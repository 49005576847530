
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadIndicator from '../components/loadIndicator';
import ErrorList from '../components/utilities/errorList';
import { useAuth } from '../providers/AuthProvider';
import { ApiErrorInfo } from '../helpers/errorInfo';
import { useOverlay } from "../providers/OverlayProvider";
import InputControl from "../components/controls/inputControl";


export default function Account() {
    let navigate = useNavigate();
    let location = useLocation();
    const { user } = useAuth();
    const { updateUser } = useAuth();
    const [alias, setAlias] = useState(user?.alias || "");
    const [firstName, setFirstName] = useState(user?.firstName || "");
    const [lastName, setLastName] = useState(user?.lastName || "");
	let [isLoading, setIsLoading] = useState<boolean>(false);
    const { showSuccessIndicatorOverlay } = useOverlay();

    const [errors, setErrors] = useState<string[]>([]);

    function onChangePassword(){
        navigate("/account/change-password", {
            state: { from: location }
        });
    }

    function onSubmit(event: React.FormEvent){
        // This will only be called if standard validation is successful.

        event.preventDefault();
        setErrors([]);

		setIsLoading(true);
        //TODO2: Convert this to use 'form' control. Form control not currently setup for 'card' layout
        updateUser(alias, firstName, lastName).then(() => {
			showSuccessIndicatorOverlay();
		}).catch(error => {
			//TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setErrors(error.errors);
            }
		}).finally(() => {
			setIsLoading(false);
		});
    }

	if(isLoading) {
		return <LoadIndicator />
	}

    return (
        <form onSubmit={onSubmit} className="needs-validation flex-grow-1 d-flex">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <h3 className="text-center">Account settings</h3>
                </div>
                <div className="card-body">
                    <div className="card-body shadow-sm mt-3">
                        <InputControl label="Email" type="email" name="email" value={user?.email} readOnly={true} className="mb-4" maxLength={30}/>
                        <div className="mb-4">
                            <InputControl label="Alias" type="text" name="alias" value={alias} onChange={(e) => setAlias(e.target.value)} maxLength={50}/>
                            <p><small><em>Alias</em> is public and will be displayed to other users</small></p>
                        </div>
                        <InputControl label="First name" type="text" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} maxLength={50} className="mb-4"/>
                        <InputControl label="Last name" type="text" name="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} maxLength={50} className="mb-4"/>
                    </div>
                    <div className="card-body shadow-sm mt-3 text-center">
                        <button type="button" onClick={onChangePassword} className="btn btn-warning me-2">Change password</button>
                    </div>
                    <ErrorList errors={errors}></ErrorList>
                </div>
                <div className="card-footer">
                    <div className="float-end">
                        <span className="fst-italic me-3 fs-smaller">Account created on {user.creationDate?.toString()}</span>
                        <button type="submit" className="btn btn-primary me-2">Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
}
