import React from 'react';

interface ILoadIndicatorProps {
    
}

function LoadIndicator(props: ILoadIndicatorProps) {

    return (
        <div className="row d-flex align-self-center">
            <div className="d-flex justify-content-center">
                <div className="spinner-border spinner-border-lg text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
}

export default LoadIndicator;