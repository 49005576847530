// Error returned from API call will be either ApiErrorInfo (If an error response was returned from the API)
// or ErrorInfo (If an unexpected error occurs)

export class ErrorInfo {
    errors: Array<string> = [];
    constructor(errors: Array<string>) {
        if(Array.isArray(errors))
        {
            this.errors = errors;
        }
        else if(errors)
        {
            this.errors.push(errors);
        }
    }
}

export class FieldErrorInfo extends ErrorInfo {
    fieldName = "";
    constructor(fieldName: string, errors: Array<string>) {
        super(errors);
        this.fieldName = fieldName;
    }
}

export class ApiErrorInfo extends ErrorInfo {
    json: any = {};
    response: any = {};
    statusCode: number;
    fieldErrors: Array<FieldErrorInfo> = [];
    isUnauthorized: boolean = false;
    isNotFound: boolean = false;

    constructor(json: any, response: Response) {
        // Parse json response for both 'generic' errors and field specific errors.
        let errorsExist = false;
        let genericErrors = [];

        if(json && json.errors && json.errors.length > 0)
        {
            errorsExist = true;
            for(let i = 0; i < json.errors.length; i++)
            {
                genericErrors.push(json.errors[i]);
            }
        }

        super(genericErrors);

        if(json && json.fieldErrors && json.fieldErrors.length > 0)
        {
            errorsExist = true;
            for(let i = 0; i < json.fieldErrors.length; i++)
            {
                let fieldError = new FieldErrorInfo(json.fieldErrors[i].fieldName, json.fieldErrors[i].errors);
                this.fieldErrors.push(fieldError);
            }
        }
        
        this.json = json;
        this.response = response;
        this.statusCode = response ? response.status : -1;
        
        if(!errorsExist)
        {
            this.errors.push("Error(s) occurred (" + this.statusCode + ").");
        }
        
        if(this.statusCode === 401) {
            this.isUnauthorized = true;
        }

        if(this.statusCode === 404) {
            this.isNotFound = true;
        }
    }
}