

import React from 'react';

function ErrorList(props: { errors: Array<string> } & React.PropsWithChildren) {

    if(!props.errors || props.errors.length < 1)
    {
        return null;
    }

    let errorsList = (
        <ul className="text-danger">
            {props.errors.map(error => <li key={error} value={error}>{error}</li>)}
        </ul>
    );

    return (
        <div>
            {errorsList}
        </div>
    );
}

export default ErrorList;