import IngredientHelper, { ApiIngredientType, IngredientType } from "./ingredientHelper"
import { Owner } from "./ownerHelper"
import RecipeHelper, { ApiRecipeIngredientType, RecipeIngredientType } from "./recipeHelper"
import Util from "./util"


export type ApiGroceryListType = {
    id: string,
    owner: Owner,
    items: ApiGroceryListItemType[],
}

export type ApiGroceryListItemType = {
    id: string,
    index: string,
    selected: boolean,
    text: string,
    recipeIngredientId: string,
    recipeIngredient: ApiRecipeIngredientType | null,
    ingredient: ApiIngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string,
    department: string
}

export type GroceryListType = {
    id: string,
    owner: Owner,
    items: GroceryListItemType[],
}

export type GroceryListItemType = {
    id: string,
    index: string,
    selected: boolean,
    text: string,
    recipeIngredientId: string,
    recipeIngredient: RecipeIngredientType | null,
    ingredient: IngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string,
    department: string
}

export default class GroceryListHelper {
    static initGroceryList(): GroceryListType {
        return {
            id: "",
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            items: []
        };
    }

    static initGroceryListFromApi(apiGroceryList: ApiGroceryListType) : GroceryListType | null {
        if(!apiGroceryList) {
            return null;
        }
        
        let groceryList: GroceryListType = {
            id: apiGroceryList.id,
            owner: {
                alias: apiGroceryList.owner.alias,
                ownerId: apiGroceryList.owner.ownerId,
                ownerId2: apiGroceryList.owner.ownerId2
            },
            items: []
        };

        apiGroceryList.items.forEach(item => {
            groceryList.items.push(this.initGroceryListItemFromApi(item));
        });

        return groceryList;
    }

    static initGroceryListItem(index: number): GroceryListItemType {
        return {
            id: "",
            index: String(index),
            selected: false,
            text: "",
            recipeIngredientId: "",
            recipeIngredient: null,
            ingredient: null,
            quantityWhole: "",
            quantityNumerator: "",
            quantityDenominator: "",
            unit: "",
            department: ""
        }
    }

    static initGroceryListItemFromApi(apiItem: ApiGroceryListItemType): GroceryListItemType {
        return {
            id: apiItem.id,
            index: String(apiItem.index),
            selected: apiItem.selected,
            text: apiItem.text,
            recipeIngredientId: Util.parseStr(apiItem.recipeIngredientId),
            recipeIngredient: apiItem.recipeIngredient ? RecipeHelper.recipeIngredientFromApi(apiItem.recipeIngredient) : null,
            ingredient: apiItem.ingredient ? IngredientHelper.ingredientFromApi(apiItem.ingredient) : null,
            quantityWhole: Util.parseStr(apiItem.quantityWhole),
            quantityNumerator: Util.parseStr(apiItem.quantityNumerator),
            quantityDenominator: Util.parseStr(apiItem.quantityDenominator),
            unit: Util.parseStr(apiItem.unit),
            department: Util.parseStr(apiItem.department)
        }
    }

    static convertToApiJson(groceryList: GroceryListType): ApiGroceryListType {
        let apiList: ApiGroceryListType = {
            id: groceryList.id,
            owner: {
                alias: groceryList.owner.alias,
                ownerId: groceryList.owner.ownerId,
                ownerId2: groceryList.owner.ownerId2
            },
            items: [],
        }

        groceryList.items.forEach(item => {
            apiList.items.push(GroceryListHelper.convertItemToApiJson(item));
        });

        return apiList;
    }

    static convertItemToApiJson(item: GroceryListItemType): ApiGroceryListItemType {
        let apiItem: ApiGroceryListItemType = {
            id: item.id,
            index: item.index,
            selected: item.selected,
            text: item.text,
            recipeIngredientId: item.recipeIngredientId,
            recipeIngredient: null,
            ingredient: item.ingredient ? IngredientHelper.convertIngredientForApi(item.ingredient) : null,
            quantityWhole: item.quantityWhole,
            quantityNumerator: item.quantityNumerator,
            quantityDenominator: item.quantityDenominator,
            unit: item.unit,
            department: item.department
        }
        return apiItem;
    }

    static getAvailableDepartments() {
        return ["Bakery", "Baby", "Baking/Spices", "Beverages", "Canned goods", "Condiments", "Dairy", "Frozen", "Health/Beauty", "Home/Paper", "International", "Liqour", "Meat/Seafood", "Misc", "Pasta", "Pet", "Produce", "Snacks"];
    }

    static getDefaultDepartmentSortOrder() {
        return ["", "Beverages", "Pet", "Baking/Spices", "International", "Health/Beauty", "Canned goods", "Pasta", "Snacks", "Condiments", "Home/Paper", "Baby", "Bakery", "Produce", "Meat/Seafood", "Dairy", "Frozen", "Misc", "Liqour"];
    }

    static isMatchingGroceryListItem(item1: GroceryListItemType, item2: GroceryListItemType) {
        return item1.index === item2.index && (item1.text === item2.text || item1.recipeIngredientId === item2.recipeIngredientId);
    }

    static isGroupedByDepartment(items: GroceryListItemType[]): boolean {
        let departmentsInList: string[] = [];
        let lastDepartment: string = "";
        
        for(var i = 0; i < items.length; i++) {
            let department = items[i].department;
            if(department !== lastDepartment) {
                // Department was already processed and is in unexpected order. Not grouped by department.
                if(departmentsInList.indexOf(department) !== -1) {
                    return false;
                }
                lastDepartment = department;
                departmentsInList.push(department);
            }
        }
    
        return true;
    }
}