
import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import Form, { IFormConfig } from '../controls/form';
import SmallForm from '../utilities/smallForm';

function SignUp() {
    const { userId, userVerified, signUp } = useAuth();
    let navigate = useNavigate();
    let location = useLocation();
    let navigateToAfterAuth = location.state?.from?.pathname || "/cookbook";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    // const [emailErrors, setEmailErrors] = useState([]);
    // const [passwordErrors, setPasswordErrors] = useState([]);
    

    useEffect(() => {
        if(userId) {
            if(userVerified) {
                navigate(navigateToAfterAuth);
            }
            else {
                navigate("/account/verify", {
                    state: {
                        from: {
                            pathname: navigateToAfterAuth
                        }
                    }
                });
            }
        }
    }, [userId, userVerified, navigateToAfterAuth, navigate])

    let formConfig: IFormConfig = {
        fields: [{
                name: "email",
                label: "Email",
                type: "email",
                value: email,
                required: true,
                autoFocus: true,
                maxLength: 100,
                onChange: (e: any) => setEmail(e.target.value),
                //**NOTE** These have not worked. There is an general issue with setting/managing error state in the form control - see comment in form control
                // errors: emailErrors,
                // setErrors: (e: any) => setEmailErrors(e)
            }, {
                name: "password",
                label: "Password",
                type: "password",
                value: password,
                required: true,
                maxLength: 30,
                onChange: (e: any) => setPassword(e.target.value),
                // errors: passwordErrors,
                // setErrors: (e: any) => setPasswordErrors(e)
            }, {
                name: "password2",
                label: "Confirm password",
                type: "password",
                value: password2,
                required: true,
                onChange: (e: any) => setPassword2(e.target.value)
            }
        ],
        validate: function() {
            let errors = [];
            if(password !== password2)
            {
                errors.push("Passwords do not match!");
            }
            return errors;
        },
        onSubmit: function() {
            return signUp(email, password);
        },
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Sign up</button>
                </div>
                <div className="row justify-content-center mb-2">
                    <div className="col-sm-8 text-center fw-lighter"><span>Already have an account? <Link to="/account/sign-in" className="text-decoration-none">Sign in</Link></span></div>
                </div>
            </div>
        ),
    };

    return (
        <SmallForm headerText="Sign up">
            <Form config={formConfig} />
        </SmallForm>
    );
}

export default SignUp;