import React, { createContext, useCallback, useEffect, useState } from 'react';
import Fetch from '../helpers/fetch';
import Jwt from '../helpers/jwt';
import { User } from '../types';

// Stateful context and user management
// https://fatmali.medium.com/use-context-and-custom-hooks-to-share-user-state-across-your-react-app-ad7476baaf32
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx

// Answer to this question contains nice visual on react update timing
// https://stackoverflow.com/questions/66993812/usestate-vs-useeffect-setting-initial-value

//TODO2: https://camjackson.net/post/9-things-every-reactjs-beginner-should-know
//      https://blog.isquaredsoftware.com/2020/05/blogged-answers-a-mostly-complete-guide-to-react-rendering-behavior/
//      https://blog.isquaredsoftware.com/presentations/pdfs/Mark%20Erikson%20-%20Introduction%20to%20React,%20Redux,%20and%20TypeScript%20(2020).pdf
//      https://www.robinwieruch.de/react-hooks-fetch-data/
//      https://overreacted.io/a-complete-guide-to-useeffect/#decoupling-updates-from-actions


const Log = function(message: string) {
    if(false) {
        console.log(message);
    }
}

// How often we check if the access token is valid (It should be less than the refresh_threshold_seconds)
const SECONDS_BETWEEN_ACCESS_TOKEN_CHECK = process.env.REACT_APP_SECONDS_BETWEEN_ACCESS_TOKEN_CHECK;
// Usage: When refresh token expiration is <this> far in the future, start trying to get a new access token
const REFRESH_THRESHOLD_SECONDS = process.env.REACT_APP_REFRESH_THRESHOLD_SECONDS;

interface IAuthContext {
    user: User,
    userId: string,
    userEmail: String,
    userVerified: Boolean,
    userVerificationCodeIssued: Date | number | null,
    signIn(email: string, password: string): Promise<void>,
    signOut(): Promise<void>,
    signUp(email: string, password: string): Promise<void>,
    verifyEmail(code: string): Promise<void>,
    generateEmailVerificationCode(): Promise<void>,
    generatePasswordResetCode(emailAddress: string): Promise<void>,
    resetPassword(email: string, securityCode: string, password: string): Promise<void>,
    changePassword(currentPassword: string, newPassword: string): Promise<void>,
    updateUser(alias: string, firstName: string, lastName: string): Promise<void>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthContext = createContext<IAuthContext | undefined>(undefined);

// props will have a 'children' property by default. This is the child ancestors of the component.
// props will have a 'user' property because that is explicitly defined when creating component (Ex: <AuthProvider user={xyz} />).
// When creating the AuthProvider here, 'value' is set and an object is returned. Consuming components can then choose which object using destructuring syntax.
export function AuthProvider(props: React.PropsWithChildren){
    Log("AuthProvider");

    const [jwt, setJwt] = useState<string>(() => {
        let jwtStored = localStorage.getItem("jwt");
        Log("init jwt from storage: " + jwtStored);
        return jwtStored || "";
    });

    Log("jwt value: " + jwt);
    if(!Fetch.Jwt && jwt) {
        Fetch.Jwt = jwt;
    }

    Fetch.OnUnauthorizedResponse = function() {
        updateJwt(null);
    }
    
    // useMemo (and useCallback) should only be used if you depend on referential equality between renders
    // useMemo is also only for optimization of rerenders when child components rely on a value from a parent component

    const [ user, setUser] = useState<User>(() => {
        Log("init user from jwt");
        return Jwt.userFromJwt(jwt);
    });
    const [ userId, setUserId ] = useState(() => {
        Log("init userId from user: " + user.id);
        return user.id;
    });
    const [ userEmail, setUserEmail ] = useState(() => {
        Log("init email from user: " + user.email);
        return user.email;
    });
    const [ userVerified, setUserVerified ] = useState(() => {
        Log("init emailVerified from user: " + user.emailVerified);
        return user.emailVerified;
    });
    const [ userVerificationCodeIssued, setUserVerificationCodeIssued ] = useState(() => {
        Log("init emailVerificationCodeIssued from user: " + user.emailVerificationCodeIssued);
        return user.emailVerificationCodeIssued;
    });

    const updateJwt = useCallback((newJwt: string | undefined | null) => {
        newJwt = newJwt || "";
        if(newJwt) {
            localStorage.setItem("jwt", newJwt);
        }
        else {
            localStorage.removeItem("jwt");
        }

        setJwt(newJwt);
        Fetch.Jwt = newJwt;
        let newUser = Jwt.userFromJwt(newJwt);
        setUser(previousValue => {
            //TODO2: Workaround to make sure state update only occurs if the user has actually changed
            //      useReducer may actually be the answer (pass it the new state and compare)
            if(previousValue.id === newUser.id &&
                previousValue.email === newUser.email &&
                previousValue.alias === newUser.alias &&
                previousValue.firstName === newUser.firstName &&
                previousValue.lastName === newUser.lastName &&
                previousValue.emailVerified === newUser.emailVerified &&
                previousValue.emailVerificationCodeIssued === newUser.emailVerificationCodeIssued) {
                Log("not setting user state to new value");
                return previousValue;
            }
            else {
                Log("setting user state to new value");
                return newUser;
            }
        });

        setUserId(newUser.id);
        setUserEmail(newUser.email);
        setUserVerified(newUser.emailVerified);
        setUserVerificationCodeIssued(newUser.emailVerificationCodeIssued);
    }, []);

    const signUp = useCallback((email: string, password: string) => {
        let data = { email, password };

        return new Fetch().postJson("account/signup", data).then((result) => {
            updateJwt(result?.token);
        });
    }, [updateJwt]);

    const signIn = useCallback((email: string, password: string) => {
        var data = { email, password };
        return new Fetch().postJson("account/signin", data).then((result) => {
            updateJwt(result?.token);
        });
    }, [updateJwt]);

    const signOut = useCallback(() => {
        return new Fetch().postJson("account/signout").finally(() => {
            updateJwt("");
        });
    }, [updateJwt]);

    const verifyEmail = useCallback((code: string) => {
        return new Fetch().postJson("account/verifyemail", code).then((result) => {
            updateJwt(result?.token);
        });
    }, [updateJwt]);

    const generateEmailVerificationCode = useCallback(() => {
        return new Fetch().postJson("account/generateemailverificationcode").then((result) => {
            updateJwt(result?.token);
        });
    }, [updateJwt]);

    const generatePasswordResetCode = useCallback((emailAddress: string) => {
        return new Fetch().postJson("account/generatepasswordresetcode", emailAddress);
    }, []);

    const resetPassword = useCallback((email: string, securityCode: string, password: string) => {
        var data = { 
            email: email,
            code: securityCode,
            password: password
        };
        return new Fetch().postJson("account/resetpassword", data);
    }, []);

    const changePassword = useCallback((currentPassword: string, newPassword: string) => {
        let data = { currentPassword, newPassword };
        return new Fetch().postJson("account/changepassword", data);
    }, []);

    const updateUser = useCallback((alias: string, firstName: string, lastName: string) => {
        let data = {
            alias: alias,
            firstName: firstName,
            lastName: lastName
        };

        return new Fetch().postJson("account/edit", data).then((result) => {
            updateJwt(result?.token);
        });
    }, [updateJwt]);

    const checkAccessToken = useCallback(() => {
        let secondsToExpiration = Jwt.getSecondsToExpiration(jwt);
        Log("checkAccessToken: Access token expires in " + Jwt.getSecondsToExpiration(jwt) + " seconds...");

        if(secondsToExpiration < Number(REFRESH_THRESHOLD_SECONDS)) {
            Log("Attempt auto-refresh access token...");
            
            let newJwt = "";
            return new Fetch().getJson("account/refreshtoken").then(result => {
                newJwt = result?.token;
                // if(result?.token) {
                //     newJwt = result?.token;
                //     localStorage.setItem("jwt", newJwt);
                // }
                // else {
                //     localStorage.removeItem("jwt");
                // }
            }).catch(error => {
                let secondsToExpiration = Jwt.getSecondsToExpiration(jwt);
                if(secondsToExpiration < 0) {
                    console.error("failed to update access token: Access token expired");
                }
                else {
                    //TODO2: Warn that session will end? Why would this happen?
                    //      - service is down 
                    //      - refresh token cookie deleted (this is only provided on sign in and email verification (sign in))
                    //      -- "For security purposes we must verify your credentials. You will be automatically signed out in 'X' minutes"
                    
                    Log("failed to update access token: " + JSON.stringify(error));
                    throw error;
                }
            }).finally(() => {
                updateJwt(newJwt);
            });
        }
    }, [jwt, updateJwt]);

    useEffect(() => {
        Log("AuthProvider-useEffect");
        // Fetch.Jwt = jwt;
        let accessTokenCheckIntervalId: NodeJS.Timeout | null = null;

        // Cannot refresh access token if user is not verified yet (they won't have a refresh token until verification)
        if(userId && userVerified && Jwt.accessTokenFromJwt(jwt)?.exp) {
            checkAccessToken();
            accessTokenCheckIntervalId = setInterval(() => {
                checkAccessToken();
            }, Number(SECONDS_BETWEEN_ACCESS_TOKEN_CHECK) * 1000);
        }

        // Cleanup function to avoid memory leaks. When component is unmounted (leaves the screen) any
        // subscriptions, timers, etc must be disposed of.
        return () => {
            if(accessTokenCheckIntervalId || accessTokenCheckIntervalId === 0) {
                clearInterval(accessTokenCheckIntervalId);
            }
        };
    }, [jwt, userId, userVerified, checkAccessToken]);

    // Return the provider with child contents inside of it. Children will access the nearest 'provided' context
    // and will be able to utilize anything passed in the 'value' prop.
    return (
        <AuthContext.Provider value={{ user, userId, userEmail, userVerified, userVerificationCodeIssued,
                                        signUp, signIn, signOut,
                                        verifyEmail, generateEmailVerificationCode, generatePasswordResetCode, resetPassword, changePassword, updateUser }}>
            {props.children}
        </AuthContext.Provider>
    );
}


// Define custom hook to use in consuming components
export function useAuth(){
    const context = React.useContext(AuthContext);
    if(context === undefined) {
        throw new Error("context is not defined. Be sure the component is wrapped in the provider before using it");
    }
    return context;
}