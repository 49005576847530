
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import LoadIndicator from './loadIndicator';
import useFetch from '../hooks/useFetch';
import RecipeHelper, { RecipeType } from '../helpers/recipeHelper';
import ErrorList from './utilities/errorList';
import { ApiErrorInfo } from '../helpers/errorInfo';
import { useOverlay } from "../providers/OverlayProvider";
import RecipeService from "../services/recipeService";
import MarkAsConsumedBtn from "./recipe/markAsConsumedBtn";

// Note: 3 is max for mobile (unless pagination-sm is used, then 5)
const cntPageLinksShown = 5;
const cntPageSize = 10;

//TODO3: Scroll into view using 'ref' (after an edit or add): https://react.dev/learn/manipulating-the-dom-with-refs#example-scrolling-to-an-element
//TODO3: On mobile or if the results are out of view, scroll first result into view instead of staying at the bottom nav panel. (only happens if the page is cached)
function MyCookbook() {
    const { user } = useAuth();
    let location = useLocation();
    let { fetchStatus, getJson, postJson } = useFetch();
    const { showSuccessIndicatorOverlay } = useOverlay();
    
    // https://www.makeuseof.com/react-query-params-update-read/
    const [searchParams, setSearchParams] = useSearchParams({});
    // const values = searchParams.entries();
    // for (const [key, value] of values) {
    //     console.log(`${key}, ${value}`);
    // }
    
    
    const [recipes, setRecipes] = useState<RecipeType[]>([]);
    const [addToMealPlanErrors, setAddToMealPlanErrors] = useState<string[]>([]);
    const [addToGroceryListErrors, setAddToGroceryListErrors] = useState<string[]>([]);
    
    const [filterText, setFilterText] = useState<string>(() => {
        return searchParams.get("f-name") || "";
    });

    const [selectedPageNumber, setSelectedPageNumber] = useState<number>(() => {
        let startingPage = searchParams.get("page");
        if(startingPage && parseInt(startingPage) > 0) {
            return parseInt(startingPage);
        }
        return 1;
    });
    const [pageLinkStartOffset, setPageLinkStartOffset] = useState<number>(1);

    // https://getbootstrap.com/docs/5.0/components/pagination/
    const [totalPageCount, setTotalPageCount] = useState<number>(1);

    

    function OnAddToMealPlan(recipe: RecipeType, isCurrent: boolean) {
        setAddToMealPlanErrors([]);

        let data = {
            isCurrent: isCurrent,
            recipes: [{
                recipe: RecipeHelper.convertRecipeToApiJson(recipe)
            }]
        };

        postJson("mealplan/addrecipe", data).then((result: any) => {
            showSuccessIndicatorOverlay("Recipe successfully added to meal plan!");
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setAddToMealPlanErrors(error.errors);
            }
        });
    }

    function OnAddToGroceryList(recipe: RecipeType) {
        setAddToGroceryListErrors([]);

        let data = RecipeHelper.convertRecipeToApiJson(recipe);

        postJson("grocerylist/addrecipe", data).then((result: any) => {
            showSuccessIndicatorOverlay("Recipe successfully added to grocery list!");
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setAddToGroceryListErrors(error.errors);
            }
        });
    }

    function onFilterTextChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        setFilterText((prev) => {
            setSelectedPageNumber(1);
            return event.target.value;
        });
    }

    useEffect(() => {
        let kvp: any = {};
        if(selectedPageNumber && selectedPageNumber > 1) {
            kvp["page"] = selectedPageNumber;

        }

        if(filterText) {
            kvp["f-name"] = filterText;
        }
        setSearchParams(kvp);
    }, [filterText, selectedPageNumber, setSearchParams])

    useEffect(() => {
        let data = {
            name: filterText || "",
            ownerId: user.id,
            pageNumber: selectedPageNumber,
            pageSize: cntPageSize,
            
        };

        RecipeService.search(getJson, data).then((result: {
            recipes: RecipeType[],
            cntTotalRecords: number
        }) => {
            setRecipes(result.recipes);
            //If decimal, be sure we show the max number of possible pages (ie: 6.2 pages = max of 7 pages)
            setTotalPageCount(Math.ceil(result.cntTotalRecords / cntPageSize));
        });
    }, [filterText, getJson, selectedPageNumber, user.id]);
    

    let recipeResults: JSX.Element | JSX.Element[] = (
        <tr>
            <td colSpan={2} className="text-center text-warning">
                No recipes found.
            </td>
        </tr>
    );

    if(!fetchStatus.isComplete()) {
        recipeResults = (
        <tr>
            <td colSpan={2}>
                <LoadIndicator />
            </td>
        </tr>
    );
    }
    else if(recipes.length > 0) {
        recipeResults = (
            recipes.map(recipe => (
                <tr key={recipe.id}>
                    <td className="border-end align-middle text-center mx-auto">
                        {/* <Link to={"/recipe/edit/" + recipe.id} state={{from: location}} className="btn p-0"><i className="bi-pencil-square"></i></Link> */}
                        <Link to={"/recipe/edit/" + recipe.id} state={{from: location}} className="btn btn-secondary">Edit</Link>
                    </td>
                    <td>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="d-flex flex-grow-1 align-items-center m-1">
                                {/* <Link to={"/recipe/edit/" + recipe.id} state={{from: location}}>{recipe.name}</Link> */}
                                <Link to={"/recipe/" + recipe.id} >{recipe.name}</Link>
                            </div>
                            {/* <span>{recipe.ingredients.length}</span> */}
                            <div className="d-flex flex-column flex-sm-row pt-1">
                                <div className="dropdown d-flex m-1">
                                    <button className="btn btn-secondary dropdown-toggle flex-fill" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Add to...
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><button className="dropdown-item flex-fill" onClick={() => OnAddToMealPlan(recipe, true)}>Current meal plan</button></li>
                                        <li><button className="dropdown-item flex-fill" onClick={() => OnAddToMealPlan(recipe, false)}>Next meal plan</button></li>
                                        <li><button className="dropdown-item flex-fill" onClick={() => OnAddToGroceryList(recipe)}>Grocery list</button></li>
                                    </ul>
                                </div>
                                <div className="d-flex m-1">
                                    <MarkAsConsumedBtn recipeId={recipe.id} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            ))
        );
    }

    let header = "My cookbook" + (user?.alias ? " - \"" + user.alias + "\"" : "");
    
    const showStartEllipse = pageLinkStartOffset > 1;

    let endingPageIdx = (pageLinkStartOffset + cntPageLinksShown);
    endingPageIdx = endingPageIdx < totalPageCount ? endingPageIdx : totalPageCount;

    const showEndingEllipse = endingPageIdx < totalPageCount;
    let pageLinks = [];
    let nextPage = pageLinkStartOffset;
    while(pageLinks.length < cntPageLinksShown && nextPage <= totalPageCount)
    {
        pageLinks.push(nextPage);
        nextPage++;
    }

    return (
        <div className="d-flex flex-grow-1">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <div>
                        <h3 className="text-center">{header}</h3>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-md-6 text-center mb-2">
                            <Link className="btn btn-secondary" to="/recipe/create">Create a new recipe</Link>
                        </div>
                        <div className="col-md-6 text-center mb-2">
                            <Link className="btn btn-secondary" to="/ingredient-list/">Manage ingredients</Link>
                        </div>
                    </div>
                    <div className="mb-2">
                        <input type="search" className="form-control" placeholder={"Filter recipes..."} value={filterText} onChange={onFilterTextChanged} />
                    </div>
                    <ErrorList errors={addToMealPlanErrors} />
                    <ErrorList errors={addToGroceryListErrors} />
                    <div className="row">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {recipeResults}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    {/* //TODO2: Make the pagination control reusable */}
                    <nav aria-label="Page navigation">
                        <ul className={"pagination pagination-sm justify-content-center"}>
                            <li className={"page-item" + (selectedPageNumber <= 1 ? " disabled" : "")}>
                                <button className={"page-link"} aria-label="First page" onClick={() => {
                                    setSelectedPageNumber(1);
                                    setPageLinkStartOffset(1);
                                }}>
                                    <span aria-hidden="true">&laquo;&laquo;</span>
                                </button>
                            </li>
                            <li className={"page-item" + (selectedPageNumber <= 1 ? " disabled" : "")}>
                                <button className={"page-link"} aria-label="Previous" onClick={() => {
                                    setSelectedPageNumber(prevSelectedPageNumber => {
                                        let newSelection = prevSelectedPageNumber <= 1 ? 1 : prevSelectedPageNumber - 1;
                                        setPageLinkStartOffset(prevPageOffsetStart => newSelection < prevPageOffsetStart ? newSelection : prevPageOffsetStart);
                                        return newSelection;
                                    });
                                }}>
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            {showStartEllipse ? (
                                <li className={"page-item"}>
                                    <button className={"page-link"} aria-label="Previous pages" onClick={() => setPageLinkStartOffset(x => x-cntPageLinksShown >= 1 ? x-cntPageLinksShown : 1)}>
                                        <span aria-hidden="true">&#x2026;</span>
                                    </button>
                                </li>
                            ) : null}
                            {
                                pageLinks.map((pageNum: number) => {
                                    return (
                                        <li key={pageNum} className={"page-item" + (selectedPageNumber === pageNum ? " active" : "")}>
                                            <button className="page-link" onClick={() => {
                                                setSelectedPageNumber(pageNum);
                                            }}>{pageNum}</button>
                                        </li>
                                    );
                                })
                            }
                            {showEndingEllipse ? (
                                <li className={"page-item"}>
                                    <button className={"page-link"} aria-label="Next pages" onClick={() => setPageLinkStartOffset(x => x+cntPageLinksShown <= totalPageCount ? x+cntPageLinksShown : x)}>
                                        <span aria-hidden="true">&#x2026;</span>
                                    </button>
                                </li>
                            ) : null}
                            <li className={"page-item" + (selectedPageNumber >= totalPageCount ? " disabled" : "")}>
                                <button className="page-link" aria-label="Next" onClick={() => {
                                    setSelectedPageNumber(prevSelectedPageNumber => {
                                        let newSelection = prevSelectedPageNumber >= totalPageCount ? totalPageCount : prevSelectedPageNumber + 1;
                                        setPageLinkStartOffset(prevPageOffsetStart => newSelection > prevPageOffsetStart + cntPageLinksShown - 1 ? prevPageOffsetStart+1 : prevPageOffsetStart);
                                        return newSelection;
                                    });
                                }}>
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                            <li className={"page-item" + (selectedPageNumber >= totalPageCount ? " disabled" : "")}>
                                <button className="page-link" aria-label="Last page" onClick={() => {
                                    setSelectedPageNumber(totalPageCount);
                                    setPageLinkStartOffset(totalPageCount - cntPageLinksShown + 1);
                                }}>
                                    <span aria-hidden="true">&raquo;&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default MyCookbook;