import { useState } from "react";
import ModalDialog from "../utilities/modalDialog";
import DatePicker from "./datePicker";
import Util from "../../helpers/util";

type ModalDatePickerPropType = {
    initialValue?: Date,
    onClose(): void,
    onOk(selectedDate: Date): void
}

export default function ModalDatePicker(props: ModalDatePickerPropType) {
    const [date, setDate] = useState<string>(() => Util.toIso8601DateString(props.initialValue || new Date()));

    return (
        <ModalDialog titleContent="Select a date" onClose={props.onClose} onOk={() => props.onOk(Util.parseIso8601Date(date)!)}>
            <DatePicker value={date} onChange={setDate}/>
        </ModalDialog>
    );
}