
import React, { useState } from 'react';
import AutoCompleteTextbox from "./autoCompleteTextbox";
import useFetch from "../../hooks/useFetch";
import IngredientHelper, { IIngredientSearchResult, IngredientType } from "../../helpers/ingredientHelper";
import RecipeHelper, { RecipeType } from "../../helpers/recipeHelper";
import Util from "../../helpers/util";


interface IIngredientAutoCompleteTextboxProps {
    isReadOnly?: boolean,
    includeRecipeAsIngredient: boolean,
    value: string,
    onTextChanged(value: string): void,
    onSelectItem(selectedValue: IIngredientSearchResult): void
}

// Table tut: https://www.youtube.com/watch?v=dYjdzpZv5yc
// Fragments (multiple child elements): https://reactjs.org/docs/fragments.html

// React tut: https://www.youtube.com/watch?v=Ke90Tje7VS0

// Drag/drop: https://www.w3schools.com/jsref/event_ondragend.asp

// When your own table gets too complicated: https://react-table.tanstack.com/docs/overview
export function IngredientAutoCompleteTextbox(props: IIngredientAutoCompleteTextboxProps) {

    const [searchResults, setSearchResults] = useState<IIngredientSearchResult[]>([]);
    const { getJson } = useFetch();

    function onTextChanged(value: string)
    {
        if(value.length > 2) {
            let data = {
                name: value,
                includeRecipes: props.includeRecipeAsIngredient !== undefined ? props.includeRecipeAsIngredient : true
            };
            
            getJson("ingredient/search", data).then((result: any) => {
                var ingredients: IngredientType[] = IngredientHelper.ingredientsFromApi(result.ingredients);
                var recipes: RecipeType[] = RecipeHelper.recipesFromApi(result.recipes);

                var results: IIngredientSearchResult[] = [];
                
                ingredients.forEach(element => {
                    results.push({
                        ingredientId: element.id,
                        recipeId: "",
                        name: element.name,
                        defaultDepartment: element.defaultDepartment,
                        defaultUnit: element.defaultUnit
                    });
                });
                
                recipes.forEach(element => {
                    results.push({
                        ingredientId: "",
                        recipeId: element.id,
                        name: element.name,
                        defaultDepartment: "",
                        defaultUnit: ""
                    });
                });

                results.sort((a, b) => Util.getTextSortOrder(a.name, b.name));
                setSearchResults(results);
            });
        }
        else {
            setSearchResults([]);
        }

        props.onTextChanged(value);
    }

    function onSearchResultSelected(selectedItem: IIngredientSearchResult)
    {
        props.onSelectItem(selectedItem);
    }

    return (
        <AutoCompleteTextbox<IIngredientSearchResult> isReadOnly={props.isReadOnly}
                                placeholder="Search for an ingredient..."
                                value={props.value}
                                results={searchResults}
                                clearResults={() => setSearchResults([])}
                                onTextChanged={onTextChanged}
                                onSelectItem={onSearchResultSelected}
                                getResultKey={x => x.ingredientId ? `ing_${x.ingredientId}` : `rec_${x.recipeId}`}
                                getResultDisplay={x => {
                                    //TODO2: Make sure text wrap is used on the other usages of autocomplete textbox
                                    return (
                                        <div className="text-wrap"><span>{x.name}</span><span className="fw-lighter">{x.recipeId ? " (recipe)" : null}</span></div>
                                    )
                                }}
                                isTextResultMatch={(text: string, result: IIngredientSearchResult) => text?.toLowerCase() === result.name?.toLowerCase()}/>
    );
}