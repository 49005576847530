import React, { useCallback, useEffect, useState } from 'react';
import {useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import Form, { IFormConfig } from '../controls/form';
import SmallForm from '../utilities/smallForm';

function VerifyEmail() {
    const { userId, userEmail, userVerified, userVerificationCodeIssued, verifyEmail, generateEmailVerificationCode } = useAuth();
    const [counter, setCounter] = useState<number | string>("");
    const [hideCounter, setHideCounter] = useState(false);
    const [disableGenerateCode, setDisableGenerateCode] = useState(false);
    
    let location = useLocation();
    // Path could be set if they navigated here from sign in attempt and have not yet verified their email
    let navigateToAfterAuth = location.state?.from?.pathname || "/cookbook";
    // console.log("navigateToAfterAuth: " + navigateToAfterAuth);

    let navigate = useNavigate();

    const [code, setCode] = useState("");


    const generateVerificationCode = useCallback(() => {
        generateEmailVerificationCode();
    }, [generateEmailVerificationCode]);


    useEffect(() => {
        let timerId: NodeJS.Timeout | null = null;

        if(userId) {
            if(userVerified) {
                navigate(navigateToAfterAuth);
            }
            else if(!userVerificationCodeIssued) {
                // User email has not been verified and an email verification code has not been issued.
                // Generate the first one automatically.
                generateVerificationCode();
            }
            else {
                // User email has not been verified but an email verification code has been issued.
                // If the code has been issued less than 60 seconds ago do not allow them to continually hit the button to regenerate a code.
                console.log("userVerificationCodeIssued: " + userVerificationCodeIssued);
                console.log("Number(userVerificationCodeIssued): " + Number(userVerificationCodeIssued));
                var secondsSinceCodeIssued = Math.floor((Date.now() - Number(userVerificationCodeIssued)) / 1000);
                console.log("secondsSinceCodeIssued: " + secondsSinceCodeIssued);
                if(secondsSinceCodeIssued < 60) {
                    // startCountDown(60 - secondsSinceCodeIssued);
                    let waitTimeSeconds = 60 - secondsSinceCodeIssued;
                    setHideCounter(false);
                    setDisableGenerateCode(true);
                    timerId = setInterval(() => {
                        setCounter(waitTimeSeconds--);
                        if(waitTimeSeconds <= 0) {
                            clearInterval(timerId as NodeJS.Timeout);
                            timerId = null;
                            setHideCounter(true);
                            setCounter("");
                            setDisableGenerateCode(false);
                        }
                    }, 1000);
                }
            }
        }
        else {
            throw new Error("User must be set to run email verification process");
        }

        // Cleanup function to avoid memory leaks. When component is unmounted (leaves the screen) any
        // subscriptions, timers, etc must be disposed of.
        return () => {
            if(timerId || timerId === 0) {
                clearInterval(timerId);
            }
        };
    }, [userId, userVerified, userVerificationCodeIssued, navigate, navigateToAfterAuth, generateVerificationCode]);

    let formConfig: IFormConfig = {
        fields: [{
                name: "code",
                label: "Code",
                type: "text",
                value: code,
                required: true,
                autoFocus: true,
                onChange: (e: any) => setCode(e.target.value),
                // errors: codeErrors,
                // setErrors: (e) => setCodeErrors(e)
            }
        ],
        // validate: function() {
            
        // },
        onSubmit: function() {
            return verifyEmail(code);
        },
        headerContent: (
            <p>An email with a verification code was sent to '{userEmail}'. Please enter the code here to verify your email address.</p>
        ),
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Verify</button>
                </div>
                <div className="row justify-content-center text-center mb-2">
                    <button type="button" className="btn btn-link" onClick={generateVerificationCode} disabled={disableGenerateCode}>Didn't receive an email? Generate a new verification code by clicking here</button>
                    <span hidden={hideCounter}>{counter}</span>
                </div>
            </div>
        )
    };

    return (
        <SmallForm headerText="Email verification">
            <Form config={formConfig} />
        </SmallForm>
    );
}

export default VerifyEmail;