
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LoadIndicator from '../components/loadIndicator';
import MealPlanRecipeList from '../components/mealPlanRecipeList';
import ErrorList from '../components/utilities/errorList';
import ModalDialog from '../components/utilities/modalDialog';
import { ApiErrorInfo } from '../helpers/errorInfo';
import MealPlanHelper, { ApiMealPlan, MealPlan, MealPlanRecipe } from '../helpers/mealPlanHelper';
import RecipeHelper, { RecipeType } from '../helpers/recipeHelper';
import useFetch from '../hooks/useFetch';
import RecipeSearch from "../components/recipeSearch";
import AsyncStatusButton from "../components/controls/asyncStatusButton";
import Util from "../helpers/util";
import { useOverlay } from "../providers/OverlayProvider";
import ModalDatePicker from "../components/controls/ModalDatePicker";

type MealPlanPropType = {
    
} & React.PropsWithChildren


export default function MealPlans(props: MealPlanPropType) {
    const { fetchStatus, getJson, postJson } = useFetch();
    let { setOverlayContent } = useOverlay();
    const [ currentMealPlan, setCurrentMealPlan] = useState<MealPlan>(() => MealPlanHelper.initMealPlan(true));
    const [ nextMealPlan, setNextMealPlan] = useState<MealPlan>(() => MealPlanHelper.initMealPlan(false));
    const [ isFirstLoadComplete, setIsFirstLoadComplete] = useState(false);

    const [searchResults, setSearchResults] = useState<RecipeType[]>([]);

    const [mealPlanApiErrors, setMealPlanApiErrors] = useState<string[]>([]);
    const [genericErrors, setGenericErrors] = useState<string[]>([]);

    const [addToGroceryListErrors, setAddToGroceryListErrors] = useState<string[]>([]);

    const [refreshDataCount, setRefreshDataCount] = useState<number>(1);

    useEffect(() => {
        // Only do a refresh when the refresh count it set at 1
        if(refreshDataCount !== 1) {
            if(refreshDataCount > 1) {
                setRefreshDataCount(prev => prev - 1);
            }
            return;
        }
        

        getJson("mealplan/all").then((mpQueryResults: ApiMealPlan[]) => {
            let currentMealPlan: MealPlan | null = null;
            let nextMealPlan: MealPlan | null = null;
            
            if(mpQueryResults && mpQueryResults.length > 0) {
                mpQueryResults.forEach(result => {
                    let mp = MealPlanHelper.mealPlanFromApi(result);
                    if(mp.isCurrent) {
                        currentMealPlan = mp;
                    }
                    else {
                        nextMealPlan = mp;
                    }
                });
            }

            if(!currentMealPlan) {
                currentMealPlan = MealPlanHelper.initMealPlan(true);
            }

            if(!nextMealPlan) {
                nextMealPlan = MealPlanHelper.initMealPlan(false);
            }

            setCurrentMealPlan(currentMealPlan);
            setNextMealPlan(nextMealPlan);
            setIsFirstLoadComplete(true);
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setGenericErrors(error.errors);
            }
        }).finally(() => {
            setRefreshDataCount(0);
        });

    }, [getJson, refreshDataCount]);

    function addRecipeToMealPlan(mealPlan: MealPlan, recipe: RecipeType) {
        let newMP: MealPlan = {...mealPlan};
    
        const idxDuplicateRecipe = newMP.recipes.findIndex((mpRecipe) => {
            return RecipeHelper.isSameRecipe(mpRecipe.recipe, recipe);
        });

        if(idxDuplicateRecipe >= 0) {
            // Strict mode triggered render of 'setMealPlans' twice (dev env only)
        }
        else {
            newMP.recipes.push({
                id: "",
                recipe: recipe
            });
        }

        return newMP;
    }

    function removeRecipeFromMealPlan(mealPlan: MealPlan, recipe: MealPlanRecipe): MealPlan {
        let newMP: MealPlan = {...mealPlan};

        const idxToRemove = newMP.recipes.findIndex((mpRecipe) => {
            return RecipeHelper.isSameRecipe(mpRecipe.recipe, recipe.recipe);
        });

        if(idxToRemove >= 0) {
            newMP.recipes.splice(idxToRemove, 1);
        }

        return newMP;
    }

    function addToMealPlan(mealPlan: MealPlan, recipe: RecipeType): MealPlan {
        let newMP = {...mealPlan};

        const idxDuplicateRecipe = newMP.recipes.findIndex((mpRecipe) => {
            //TODO2: This will have to get updated if users can ever add recipes from other people that may be the same name
            return RecipeHelper.isSameRecipe(mpRecipe.recipe, recipe);
        });

        if(idxDuplicateRecipe >= 0) {
            //TODO2: User tried to add recipe twice (increase qty?)
        }
        else {
            newMP.recipes.push({
                id: "",
                recipe: recipe
            });
        }

        return newMP;
    }

    const onAddToMealPlan = useCallback((recipe: RecipeType, isCurrent: boolean): Promise<boolean> => {
        setMealPlanApiErrors([]);
        
        let data = {
            isCurrent: isCurrent,
            recipes: [{
                recipe: RecipeHelper.convertRecipeToApiJson(recipe)
            }]
        };

        return postJson("mealplan/addrecipe", data).then((result: any) => {
            //TODO2: Optimize. Instead of requerying for data add the recipe
            // dispatch({ type: MEALPLAN_ACTION_TYPE.RECIPE_ADDED, payload: { recipe: recipe, isCurrent: isCurrent } });
            setRefreshDataCount(prev => prev + 1);
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setMealPlanApiErrors(error.errors);
                return false;
            }
            else
            {
                //TODO2: Error handling elsewhere should account for unexpected errors. ie: TypeError (or some other JS error that happens in a service method)
                throw error;
            }
        });
    }, [postJson]);

    function removeMealPlanRecipes(mpRecipes: MealPlanRecipe[], isCurrent: boolean): Promise<boolean> {
        setMealPlanApiErrors([]);
        
        let data = {
            isCurrent: isCurrent,
            recipes: MealPlanHelper.convertMealPlanRecipesToApiJson(mpRecipes)
        };

        return postJson("mealplan/removerecipes", data).then((result: any) => {
            //TODO2: Optimize. Instead of requerying for data remove the recipe
            // dispatch({ type: MEALPLAN_ACTION_TYPE.RECIPE_REMOVED, payload: { mpRecipe: mpRecipe, isCurrent: isCurrent } });
            setRefreshDataCount(prev => prev + 1);
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setMealPlanApiErrors(error.errors);
            }
            else
            {
                //TODO2: Error handling elsewhere should account for unexpected errors. ie: TypeError (or some other JS error that happens in a service method)
                throw error;
            }
            return false;
        });
    }

    function onRecipePromoted(mpRecipe: MealPlanRecipe) {
        setMealPlanApiErrors([]);
        let data = RecipeHelper.convertRecipeToApiJson(mpRecipe.recipe);

        return postJson("mealplan/promoterecipe", data).then((result: any) => {
            //TODO2: Optimize. Instead of requerying for data remove the recipe
            // dispatch({ type: MEALPLAN_ACTION_TYPE.RECIPE_REMOVED, payload: { mpRecipe: mpRecipe, isCurrent: isCurrent } });
            setRefreshDataCount(prev => prev + 1);
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setMealPlanApiErrors(error.errors);
            }
            else
            {
                //TODO2: Error handling elsewhere should account for unexpected errors. ie: TypeError (or some other JS error that happens in a service method)
                throw error;
            }
            return false;
        });
    }

    function onRecipeDemoted(mpRecipe: MealPlanRecipe): Promise<boolean> {
        setMealPlanApiErrors([]);
        let data = RecipeHelper.convertRecipeToApiJson(mpRecipe.recipe);

        return postJson("mealplan/demoterecipe", data).then((result: any) => {
            //TODO2: Optimize. Instead of requerying for data remove the recipe
            // dispatch({ type: MEALPLAN_ACTION_TYPE.RECIPE_REMOVED, payload: { mpRecipe: mpRecipe, isCurrent: isCurrent } });
            setRefreshDataCount(prev => prev + 1);
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setMealPlanApiErrors(error.errors);
            }
            else
            {
                //TODO2: Error handling elsewhere should account for unexpected errors. ie: TypeError (or some other JS error that happens in a service method)
                throw error;
            }
            return false;
        });
    }

    function onAddToGroceryList(mpRecipe: MealPlanRecipe): Promise<boolean> {
        setAddToGroceryListErrors([]);

        let data = RecipeHelper.convertRecipeToApiJson(mpRecipe.recipe);

        return postJson("grocerylist/addrecipe", data).then((result: any) => {
            return true;
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setAddToGroceryListErrors(error.errors);
            }
            return false;
        });
    }

    function onAddAllToGroceryList(mpRecipes: MealPlanRecipe[]): Promise<boolean> {
        setAddToGroceryListErrors([]);

        let data: any[] = [];
        mpRecipes.forEach(mpRecipe => data.push(RecipeHelper.convertRecipeToApiJson(mpRecipe.recipe)));

        return postJson("grocerylist/addrecipes", data).then((result: any) => {
            return true;
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setAddToGroceryListErrors(error.errors);
            }
            return false;
        });
    }

    function selectDate(): Promise<Date> {
        return new Promise((resolve, reject) => {
            let onClose = function() {
                setOverlayContent(null);
                reject();
            };

            setOverlayContent((
                <ModalDatePicker onClose={onClose} onOk={(date) => {
                    setOverlayContent(null);
                    resolve(date)
                }} />
            ));
        });
    }

    function markAsConsumed(mpRecipes: MealPlanRecipe[], usageDate: Date): Promise<boolean> {
        //TODO2: Should be a helper for deep cloning
        let mp: MealPlan = {
            id: currentMealPlan.id,
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            isCurrent: currentMealPlan.isCurrent,
            recipes: mpRecipes
        };

        let data: {
            mealPlan: ApiMealPlan,
            usageDate: string
        } = {
            mealPlan: MealPlanHelper.convertMealPlanToApiJson(mp),
            usageDate: Util.toIso8601DateString(usageDate)
        };

        return postJson("mealplan/markrecipesasused", data).then(() => {
            setRefreshDataCount(prev => prev + 1);
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setMealPlanApiErrors(error.errors);
            }
            return false;
        });
    }

    const onRecipeSearchInitiated = useCallback((): void => {
		setSearchResults([]);
	}, []);

    const onRecipeSearchCompleted = useCallback((results: RecipeType[]): void => {
		setSearchResults(results);
	}, []);

    const onRecipeQuickAddCompleted = useCallback(async (recipe: RecipeType): Promise<void> => {
        await onAddToMealPlan(recipe, true);
	}, [onAddToMealPlan]);

    const recipeSearch = useMemo(() => {
        return <RecipeSearch onSearchCompleted={onRecipeSearchCompleted} onSearchInitiated={onRecipeSearchInitiated} onQuickAddSuccess={onRecipeQuickAddCompleted} />
    }, [onRecipeQuickAddCompleted, onRecipeSearchCompleted, onRecipeSearchInitiated]);

    if(genericErrors.length > 0) {
        return (
            <ModalDialog onClose={() => setGenericErrors([])} isErrorLayout={true}>
                <ErrorList errors={genericErrors} />
            </ModalDialog>
        );
    }

    if(!fetchStatus.isComplete() && !isFirstLoadComplete) {
        return <LoadIndicator />
    }

    let mpContent = null;
    if(currentMealPlan && nextMealPlan) {
        mpContent = (
            <div>
                <div className="card-body shadow-sm">
                    <MealPlanRecipeList title={"Current meal plan"}
                                        recipes={currentMealPlan.recipes}
                                        markAsConsumed={(mpRecipes: MealPlanRecipe[]) => selectDate().then((date) => markAsConsumed(mpRecipes, date))}
                                        removeMealPlanRecipes={(mpRecipes: MealPlanRecipe[]) => removeMealPlanRecipes(mpRecipes, true)}
                                        onDemoteRecipe={onRecipeDemoted}
                                        onAddToGroceryList={onAddToGroceryList}
                                        onAddAllToGroceryList={onAddAllToGroceryList}/>
                </div>
                <div className="card-body shadow-sm">
                    <MealPlanRecipeList title={"Next meal plan"}
                                        recipes={nextMealPlan.recipes}
                                        removeMealPlanRecipes={(mpRecipes: MealPlanRecipe[]) => removeMealPlanRecipes(mpRecipes, false)}
                                        onPromoteRecipe={onRecipePromoted}
                                        onAddToGroceryList={onAddToGroceryList}
                                        onAddAllToGroceryList={onAddAllToGroceryList}/>
                </div>
                <div className="card-body">
                    <ErrorList errors={mealPlanApiErrors} />
                    <ErrorList errors={addToGroceryListErrors} />
                </div>
            </div>
        );
    }

    return (
        <div className="d-flex flex-grow-1">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <h3 className="text-center">Meal planning</h3>
                </div>
                <div className="card-body row">
                    <div className="col-xxl-4">
                        <div className="card mb-2">
                            {mpContent}
                        </div>
                    </div>
                    <div className="col-xxl-8">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="searchAccordion">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#searchAccordionContent" aria-expanded="true" aria-controls="searchAccordionContent">
                                        Search criteria
                                    </button>
                                </h2>
                                <div id="searchAccordionContent" className="accordion-collapse collapse show" aria-labelledby="searchAccordion">
                                    <div className="accordion-body">
                                        {/* This is memoized so that when the search is completed and the MealPlan component rerenders we don't have to rerender the recipe search component, 
                                        which triggers the props to change causing an infinite query.. https://www.developerway.com/posts/react-re-renders-guide */}
                                        {recipeSearch}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion mt-2">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="searchResultAccordion">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#searchResultAccordionContent" aria-expanded="true" aria-controls="searchAccordionContent">
                                        Search results
                                    </button>
                                </h2>
                                <div id="searchResultAccordionContent" className="accordion-collapse collapse show" aria-labelledby="searchResultAccordion">
                                    <div className="accordion-body">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchResults.map((recipe: RecipeType) => {
                                                    return (
                                                        <tr key={recipe.id}>
                                                            <td>{recipe.name}</td>
                                                            <td><AsyncStatusButton onClick={() => onAddToMealPlan(recipe, true)}>Add current</AsyncStatusButton></td>
                                                            <td><AsyncStatusButton onClick={() => onAddToMealPlan(recipe, false)}>Add next</AsyncStatusButton></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}