
import React, { useCallback } from 'react';
import { RecipeInstructionType } from "../helpers/recipeHelper";
import styles from "./instructionList.module.scss"
import InputControl from "./controls/inputControl";

function getItemKey(item: RecipeInstructionType): string {
    return item.index;
}

type InstructionListPropType = {
	items: RecipeInstructionType[],
    onItemsChanged(items: RecipeInstructionType[]): void,
} & React.PropsWithChildren

export default function InstructionList(props: InstructionListPropType) {

    let rows = props.items;
    let rowDragged: HTMLElement | null = null;
    let lastRowEntered: HTMLElement | null = null;
    

    function printElementDetails(element: HTMLElement | null): string {
        if(!element) {
            return "Element null";
        }
        return "Idx(table):" + getRowIndexInTable(element) + ", Idx(row): " + getRowIndex(element) + ", key: " + getRowKey(element);
    }

    function getRowKey(htmlElement: HTMLElement): string {
        // Get closest parent that is a TR tag
        return htmlElement.closest("tr")?.dataset.key || "";
    }

    const getRowIndex = useCallback((element: HTMLElement): number => {
        const elementRowKey = getRowKey(element);
        for (let i = 0; i < rows.length; i++) {
            if(getItemKey(rows[i]) === elementRowKey) {
                return i;
            }
        }
        return -1;
    }, [rows]);

    function getRowIndexInTable(htmlElement: HTMLElement) {
        let tbRows: Element[] = [];
        let tBody: HTMLElement | null = htmlElement;
        let element: HTMLElement | null = htmlElement;
        while(tBody) {
            if(tBody.tagName === "TBODY") {
                tbRows = Array.from(tBody.children);
                break;
            }
            tBody = tBody.parentElement;
        }

        while(element) {
            if(element.tagName === "TR") {
                break;
            }
            element = element.parentElement;
        }

        return tbRows.indexOf(htmlElement);
    }

    function onTextChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        rows[rowIdx].text = newValue;
        props.onItemsChanged(rows);
    }

    function removeRow(event: React.MouseEvent<HTMLButtonElement>) {
        let eventIndex = getRowIndex(event.target as HTMLElement);
        rows.splice(eventIndex, 1);
        props.onItemsChanged(rows);
    }

    function toggleRowType(event: React.MouseEvent<HTMLButtonElement>) {
        let rowIdx = getRowIndex(event.target as HTMLElement);
        rows[rowIdx].isHeader = !rows[rowIdx].isHeader;
        props.onItemsChanged(rows);
    }




    function onRowDragStart(event: React.DragEvent<HTMLTableRowElement>) {
        console.log("onRowDragStart ::: " + printElementDetails(event.target as HTMLElement));
        rowDragged = event.target as HTMLElement;

        // let tbRows = Array.from(rowDragged.parentNode.children);

        // Could set styles for drag row here
    }

    function onRowDrag(event: React.DragEvent<HTMLTableRowElement>) {
        // console.log("onRowDrag: " + getRowKey(event.target));
    }

    function onRowDragEnd(event: React.DragEvent<HTMLTableRowElement>) {
        // The final 'drag' event called regardless of whether something was moved or not
        console.log("onRowDragEnd: " + printElementDetails(event.target as HTMLElement));

        rowDragged = null;
        lastRowEntered = null;
        props.onItemsChanged(rows);
    }

    function onRowDragEnter(event: React.DragEvent<HTMLTableRowElement>) {
        if(!(event.target instanceof HTMLElement))
            return;

        let rowElement: HTMLElement | null = event.target;

        while(rowElement) {
            if(rowElement.tagName === "TR") {
                break;
            }
            rowElement = rowElement.parentElement;
        }
        
        lastRowEntered = rowElement;
        
        console.log("onRowDragEnter ::: Dragged: " + printElementDetails(rowDragged) + ", over: " + printElementDetails(lastRowEntered));

        // Below is used to visualize the row change before it is done (it actually alters the table though and there are some indexing side effects)

        // let tbRows = Array.from(lastRowEntered.parentNode.children);
        // let idxRowEntered = tbRows.indexOf(lastRowEntered);
        // let idxRowDragged = tbRows.indexOf(rowDragged);
        
        // if(idxRowEntered > idxRowDragged) {
        //     lastRowEntered.after(rowDragged);
        // }
        // else if(idxRowEntered < idxRowDragged) {
        //     lastRowEntered.before(rowDragged);
        // }
    }

    function onRowDragOver(event: React.DragEvent<HTMLTableRowElement>) {
        // "By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element"
        event.preventDefault();
    }

    function onRowDragLeave(event: React.DragEvent<HTMLTableRowElement>) {
        console.log("onRowDragLeave ::: " + printElementDetails(event.target as HTMLElement));
    }

    function onRowDrop(event: React.DragEvent<HTMLTableRowElement>) {
        // This will not be called if the user cancels out of the drag action. OnDragEnd is the event that is called once the
        // drag action is complete (regardless of if something was moved)

        // "Call preventDefault() to prevent the browser default handling of the data (default is open as link on drop)"
        event.preventDefault();

        console.log("onRowDrop      ::: Dragged: " + printElementDetails(rowDragged) + ", over: " + printElementDetails(lastRowEntered));
        // let originalIdx = event.dataTransfer.getData("text");
        let idxRowDragged = getRowIndex(rowDragged as HTMLElement);
        let idxLastRowEntered = getRowIndex(lastRowEntered as HTMLElement);

        let row = rows.splice(idxRowDragged, 1)[0];
        rows.splice(idxLastRowEntered, 0, row);
    }



    if(!rows || rows.length < 1) {
        return null;
    }
    

    let tableBodyContent = rows.map(row => {
        let textField = (
            <div className="d-flex align-items-center">
                {/* <textarea className={"form-control p-1" + (row.isHeader && row.text ? " form-control-plaintext fw-bold" : "")} value={row.text} onChange={onTextChanged} /> */}
                {/* <input type="text" className={"form-control p-1" + (row.isHeader && row.text ? " form-control-plaintext fw-bold" : "")} value={row.text} onChange={onTextChanged}/> */}
                <InputControl type="textarea" maxLength={row.isHeader ? 50 : 250} additionalInputClass={"p-1" + (row.isHeader && row.text ? " form-control-plaintext fw-bold" : "")} name="text" value={row.text} onChange={onTextChanged} />
            </div>
        );
        
        // Icons: https://icons.getbootstrap.com/#icon-font
        return (
            <tr data-isheader={row.isHeader} key={getItemKey(row)} data-key={getItemKey(row)} draggable="true" onDragStart={onRowDragStart} onDrag={onRowDrag} onDragEnd={onRowDragEnd} onDragEnter={onRowDragEnter} onDragOver={onRowDragOver} onDragLeave={onRowDragLeave} onDrop={onRowDrop}>
                <td className={" border-end"}>
                    <button type="button" className="me-1 border-0" onClick={toggleRowType}><i className={row.isHeader ? "bi-arrow-right" : "bi-arrow-left"}></i></button>
                    <button type="button" className="me-1 border-0" onClick={removeRow}><i className="bi-dash"></i></button>
                </td>
                <td>{textField}</td>
            </tr>
        )}
    );

    return (
        <div className={"row " + styles.instructionListStyle}>
            <table>
                <colgroup>
                    {/* Actions */}
                    <col span={1} style={{width: "10%"}} />
                    {/* Text */}
                    <col span={1} style={{width: "90%"}} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {tableBodyContent}
                </tbody>
            </table>
        </div>
    );
}