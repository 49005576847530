import React, { useCallback, useState } from "react";
import InputControl from "./inputControl";


interface IAutocompleteTextboxProps<T> {
    isReadOnly?: boolean,
    placeholder?: string,
    value: string,
    results: T[],
    onTextChanged(value: string): void,
    clearResults(): void,
    onSelectItem(selectedValue: T): void,
    getResultKey(result: T): string,
    getResultDisplay(result: T): JSX.Element | string,
    isTextResultMatch(text: string, result: T): boolean
}


// https://www.w3schools.com/howto/howto_js_autocomplete.asp
export default function AutoCompleteTextbox<T extends object>(props: IAutocompleteTextboxProps<T> & React.PropsWithChildren) {

    const [selectedResultIdx, setSelectedResultIdx] = useState(-1);

    const onSearchTextChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        props.onTextChanged(e.target.value);
    }, [props]);

    // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "ArrowDown") {
            setSelectedResultIdx(x => x + 1);
        }
        else if (e.key === "ArrowUp") {
            // lowest possible value is -1
            setSelectedResultIdx(x => (x < 0) ? -1 : x - 1);
        }
        else if (e.key === "Enter") {
            // Enter key
            e.preventDefault();
            
            if(selectedResultIdx >= 0 && selectedResultIdx < props.results.length) {
                props.onSelectItem(props.results[selectedResultIdx]);
                props.clearResults();
                setSelectedResultIdx(-1);
            }
        }
        else if(e.key === "Tab") {
            // If user tabs away from control and the control text matches the results, select the result
            if(selectedResultIdx < 0) {
                for(var result of props.results) {
                    if(props.isTextResultMatch(props.value, result)) {
                        props.onSelectItem(result);
                        break;
                    }
                }
            }
        }
    }, [selectedResultIdx, props]);

    const onClickSelection = useCallback((selectedItem: any) => {
        props.onSelectItem(selectedItem);
        props.clearResults();
        setSelectedResultIdx(-1);
    }, [props]);

    const onBlur = useCallback(() => {
        props.clearResults();
        setSelectedResultIdx(-1);
    }, [props]);

    function onMouseDownResult(e: React.MouseEvent<HTMLButtonElement>) {
        // This prevents the div 'onBlur' from firing right away - which, if that fires immediately, clicking a result item does not fire onClick becuase the div 'onBlur' is fired first.
        e.preventDefault();
    }

    return (
        <div className="dropdown mb-2" onClick={() => console.log("clicked in div")} onBlurCapture={onBlur}>
            <InputControl type="search" maxLength={50} placeholder={props.isReadOnly ? "" : (props.placeholder || "Search...")} name="name" value={props.value} onChange={onSearchTextChanged} onKeyDown={onKeyDown} readOnly={props.isReadOnly} autocomplete="off"/>
            {props.isReadOnly ? null :
                <ul className={"dropdown-menu w-100" + (props.results.length > 0 ? " show" : "")} onFocus={() => console.log("ul is focused")}>
                    {props.results.map((result: T, idx: number) => {
                        return <li key={props.getResultKey(result)}><button className={"dropdown-item" + (idx === selectedResultIdx ? " active" : "")} onMouseDown={onMouseDownResult} onClick={(e) => onClickSelection(result)}>{props.getResultDisplay(result)}</button></li>
                    })}
                </ul>
            }
        </div>
    )
}