
type DatePickerPropType = {
    value: string,
    onChange(date: string): void
}

export default function DatePicker(props: DatePickerPropType) {
    // Format is yyyy-MM-dd
    
    return (
        <input type="date" value={props.value} onChange={(e) => props.onChange(e.target.value)}></input>
    )
}