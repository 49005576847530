

type IngredientQuantityPropType = {
    isReadOnly?: boolean,
    qtyWhole: string,
    qtyNumerator: string,
    qtyDenominator: string,
    onQtyWholeChanged?(event: React.ChangeEvent<HTMLInputElement>): void
    onQtyNumeratorChanged?(event: React.ChangeEvent<HTMLInputElement>): void
    onQtyDenominatorChanged?(event: React.ChangeEvent<HTMLInputElement>): void
} & React.PropsWithChildren

export default function IngredientQuantity(props: IngredientQuantityPropType) {

    if(props.isReadOnly) {
        let qty = <span>&nbsp;{props.qtyWhole}</span>;

        // Only display fractional portion if there is a fractional portion
        if(props.qtyNumerator) {
            qty = <span>{props.qtyWhole}<sup>{props.qtyNumerator}</sup>/<sub>{props.qtyDenominator}</sub></span>
        }
        return qty;
    }

    return (
        <div className="row m-0">
            <div className="col-7 d-flex align-items-center p-1">
                <input type="number" className="form-control p-1" value={props.qtyWhole} onChange={props.onQtyWholeChanged}/>
            </div>
            <div className="col-5 p-1">
                <input type="number" className="form-control form-control-sm mb-1 p-1" value={props.qtyNumerator} onChange={props.onQtyNumeratorChanged}/>
                <input type="number" className="form-control form-control-sm p-1" value={props.qtyDenominator} onChange={props.onQtyDenominatorChanged}/>
            </div>
        </div>
    )
}