import React from 'react';
import { useAuth } from '../../providers/AuthProvider';
import Form, { IFormConfig } from '../controls/form';
import { Link } from 'react-router-dom';

type ConfirmPasswordResetType = {
    email: string,
    setEmail(e: any): void,
    onConfirm(): void
};

export default function ConfirmPasswordReset(props: ConfirmPasswordResetType & React.PropsWithChildren) {

    const { generatePasswordResetCode } = useAuth();

    let formConfig: IFormConfig = {
        fields: [{
                name: "email",
                label: "Email",
                type: "email",
                value: props.email,
                required: true,
                autoFocus: true,
                onChange: (e: any) => props.setEmail(e.target.value)
            }
        ],
        onSubmit: function() {
            return generatePasswordResetCode(props.email).then(() => {
                props.onConfirm();
            });
        },
        headerContent: (
            <p>To reset your password we must confirm you are the owner of the account. Enter your email address and click the 'Reset password' button below to generate a security code. You must provide the code to verify your account ownership.</p>
        ),
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Reset password</button>
                </div>
                <div className="row justify-content-center mb-2">
                    <div className="col-sm-8 text-center fw-lighter"><Link to="/account/sign-in" className="text-decoration-none">Back to sign in</Link></div>
                </div>
                <div className="row justify-content-center mb-2">
                    <div className="col-sm-8 text-center fw-lighter"><span>Don't have an account? <Link to="/account/sign-up" className="text-decoration-none">Sign up</Link></span></div>
                </div>
            </div>
        )
    };

    return (
        <Form config={formConfig} />
    );
}