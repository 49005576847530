
type CloseButtonPropType = {
    onClick(event: React.MouseEvent<HTMLButtonElement>): void
} & React.PropsWithChildren

export default function CloseButton(props: CloseButtonPropType) {

    return (
        <button type="button" className="btn-close" onClick={props.onClick}></button>
    )
}