
import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import Form, { IFormConfig } from '../controls/form';
import SmallForm from '../utilities/smallForm';


export default function SignIn() {
    const { user, signIn } = useAuth();
    let navigate = useNavigate();
    let location = useLocation();

    // If user was sent to signin due to auth requirement, once authenticated send them to where
    // they wanted to go. If they initiated signin then once authenticated send them to their cookbook.
    let navigateToAfterAuth = location.state?.from?.pathname || "/cookbook";
    
    
    const [email, setEmail] = useState(location.state?.email || "");
    const [password, setPassword] = useState("");

    useEffect(() => {
        console.log("user.id: " + user?.id);
        console.log("user.emailVerified: " + user?.emailVerified);
        if(user?.id) {
            if(user.emailVerified) {
                navigate(navigateToAfterAuth);
            }
            else {
                navigate("/account/verify", {
                    state: {
                        from: {
                            pathname: navigateToAfterAuth
                        }
                    }
                });
            }
        }
    }, [user, navigateToAfterAuth, navigate]);

    let formConfig: IFormConfig = {
        fields: [{
                name: "email",
                label: "Email",
                type: "email",
                value: email,
                required: true,
                autoFocus: true,
                onChange: (e: any) => setEmail(e.target.value)
            }, {
                name: "password",
                label: "Password",
                type: "password",
                value: password,
                required: true,
                autoFocus: false,
                onChange: (e: any) => setPassword(e.target.value)
            }
        ],
        onSubmit: function() {
            return signIn(email, password);
        },
        headerContent: location.state?.message ? (<p>{location.state?.message}</p>) : null,
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Sign in</button>
                </div>
                <div className="row justify-content-center mb-2">
                    <div className="col-sm-8 text-center fw-lighter"><span>Don't have an account? <Link to="/account/sign-up" className="text-decoration-none">Sign up</Link></span></div>
                </div>
                <hr className="w-75"/>
                <div className="row justify-content-center text-center mb-2">
                    <Link to="/account/reset-password" className="col-sm-8 fw-lighter" state={{ email: email }}>Forgot password?</Link>
                </div>
            </div>
        )
    };

    return (
        <SmallForm headerText="Sign in">
            <Form config={formConfig} />
        </SmallForm>
    );
}