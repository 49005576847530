import { User } from "../types";
import Util from "./util";

type JwtPayload = {
    nbf: number,
    exp: number,
    iat: number,
    nameid: string,
    email: string,
    alias: string,
    firstName: string,
    lastName: string,
    emailVerified: string,
    emailVerificationCodeIssued: string,
    creationDate: string
}

type AccessToken = {
    nbf: Date,
    exp: Date,
    iat: Date
}

class Jwt {
    static decodePayload(jwt: string): JwtPayload | null {
        if(!jwt) {
            return null;
        }

        let jwtPayload = null;
        try {
            // Decoding the JWT
            // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
            let jwtPayloadBase64Url = jwt.split(".")[1];
            let jwtPayloadBase64 = jwtPayloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jwtPayloadJson = decodeURIComponent(atob(jwtPayloadBase64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            
            jwtPayload = JSON.parse(jwtPayloadJson);
            // console.log("jwtPayload: " + JSON.stringify(jwtPayload));
        } catch (error) {
            console.log("Error parsing jwt: " + error);
        }
        return jwtPayload;
    }

    static accessTokenFromJwt(jwt: string) {
        const jwtPayload = this.decodePayload(jwt);
        if(!jwtPayload) {
            return null;
        }

        return this.accessTokenFromJwtPayload(jwtPayload);
    }

    static accessTokenFromJwtPayload(jwtPayload: JwtPayload): AccessToken {
        var t = {
            // These dates are 'epoch' time (time from start)
            nbf: new Date(jwtPayload.nbf * 1000),
            exp: new Date(jwtPayload.exp * 1000),
            iat: new Date(jwtPayload.iat * 1000)
        };

        return t;
    }

    static userFromJwt(jwt: string): User {
        const jwtPayload = this.decodePayload(jwt);
        if(!jwtPayload) {
            return {
                id: "",
                email: "",
                alias: "",
                firstName: "",
                lastName: "",
                emailVerified: false,
                emailVerificationCodeIssued: null,
                creationDate: null
            };
        }

        return this.userFromJwtPayload(jwtPayload);
    }

    static userFromJwtPayload(jwtPayload: JwtPayload): User {
        let u = {
            id: jwtPayload.nameid,
            email: jwtPayload.email,
            alias: jwtPayload.alias,
            firstName: jwtPayload.firstName,
            lastName: jwtPayload.lastName,
            emailVerified: Util.ApiBoolean(jwtPayload.emailVerified),
            emailVerificationCodeIssued: jwtPayload.emailVerificationCodeIssued ? new Date(jwtPayload.emailVerificationCodeIssued) : null,
            creationDate: jwtPayload.creationDate ? new Date(jwtPayload.creationDate) : null
        };

        return u;
    }

    static getSecondsToExpiration(jwt: string) {
        const jwtPayload = this.decodePayload(jwt);
        if(!jwtPayload) {
            return -1;
        }

        let now = new Date().getTime();
        let tokenExpiration = Jwt.accessTokenFromJwtPayload(jwtPayload).exp;
        if(!tokenExpiration) {
            return -1;
        }

        let secondsToExpiration = (tokenExpiration.getTime() - now) / 1000;
        // console.log("Access token expires in " + secondsToExpiration + " seconds...");
        return secondsToExpiration;
    }
}

export default Jwt;