

export default class Util {
    
    static secondsToTimeString(seconds: number): string {
        seconds = seconds || 0;
        // console.log("seconds: " + seconds);
        let minutes = 0;
        let hours = 0;
        let days = 0;
        if(seconds > 60){
            minutes = Math.trunc(seconds / 60);
            seconds = seconds % 60;
            // console.log("minutes: " + minutes);
            // console.log("seconds: " + seconds);
            if(minutes > 60) {
                hours = Math.trunc(minutes / 60);
                minutes = minutes % 60;
                // console.log("hours: " + hours);
                // console.log("minutes: " + minutes);
                if(hours > 24) {
                    days = Math.trunc(hours / 24);
                    hours = hours % 24;
                    // console.log("days: " + days);
                    // console.log("hours: " + hours);
                }
            }
        }

        let timeString = "";
        if(days > 0) {
            timeString += days + " day" + (days > 1 ? "s" : "");
        }

        if(hours > 0) {
            timeString += (!!timeString ? " " : "") + hours + " hour" + (hours > 1 ? "s" : "");
        }

        if(minutes > 0) {
            timeString += (!!timeString ? " " : "") + minutes + " minute" + (minutes > 1 ? "s" : "");
        }

        if(seconds > 0) {
            timeString += (!!timeString ? " " : "") + seconds + " second" + (seconds > 1 ? "s" : "");
        }

        timeString = timeString || "0 minutes";
        return timeString;
    }

    static secondsToMinutes(seconds: number): number{
        // 1 min 20 sec -> 80 sec -> 1.3 min
        seconds = seconds || 0;
        let minutes = 0;
        if(seconds > 0){
            if(seconds > 60) {
                minutes = Math.trunc(seconds / 60);
            }
            minutes += ((seconds % 60) + Number.EPSILON) / 60;
        }

        return minutes;
    }

    static minutesToSeconds(minutes: number): number {
        minutes = minutes || 0;
        return minutes * 60;
    }

    static parseInt(value: string | undefined | null): number {
        value = value || "";
        return parseInt(value) || 0;
    }

    static parseStr(value: string | undefined | null): string {
        if(value === undefined || value === null) {
            return "";
        }

        return String(value);
    }

    static parseIso8601Date(value: string): Date | null {
        let date = null;
        var r = /^\d{4}-\d{2}-\d{2}$/;
        if(value && r.test(value)) {
            const dateParts = value.split("-");
            date = new Date(Util.parseInt(dateParts[0]), Util.parseInt(dateParts[1]) - 1, Util.parseInt(dateParts[2]));
        }
        return date;
    }

    static parseDate(value: string): Date | null {
        if(!value) {
            return null;
        }

        let date = this.parseIso8601Date(value);
        if(!date) {
            date = new Date(value);
        }
        return date;
    }

    static parseDateStrict(value: string): Date {
        return this.parseDate(value) || new Date(2000, 0, 1);
    }

    static isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    static toIso8601DateString(date: Date): string {
        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if(month.length < 2)
            month = "0" + month;

        if(day.length < 2)
            day = "0" + day;

        return `${year}-${month}-${day}`;
    }

    /**
     * Get the next day after the provided value (day + 1)
     * @param date 
     * @returns The date/time 24 hours after the provided date
     */
    static getNextDayDate(date: Date): Date {
        const nextDay = new Date(date);
        nextDay.setHours(date.getHours() + 24);
        return nextDay;
    }

    static ApiBoolean(value: string): boolean {
        return Boolean(value && value.toUpperCase() !== "FALSE")
    }

    static getRandomItem(array: Array<any>) {
        return array[Math.floor(Math.random() * array.length)];
    }

    static getTextSortOrder(a: string, b: string) {
        return a?.toUpperCase() > b?.toUpperCase() ? 1 : -1;
    }
}