
import React, { useCallback, useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import LoadIndicator from '../components/loadIndicator';
import ErrorList from "../components/utilities/errorList";
import { ApiErrorInfo } from "../helpers/errorInfo";
import IngredientHelper, { IngredientType } from "../helpers/ingredientHelper";
import UnitOfMeasureDropdown from "../components/controls/unitOfMeasureDropdown";
import GroceryDepartmentDropdown from "../components/controls/groceryDepartmentDropdown";
import styles from "../components/recipe/ingredientListView.module.scss";
import Util from "../helpers/util";
import InputControl from "../components/controls/inputControl";

function removeIngredient(list: IngredientType[], toRemove: IngredientType) {
    let idx = list.indexOf(toRemove);
    if(idx >= 0) {
        list.splice(idx, 1);
    }
    return [...list];
}

export default function IngredientListView() {

    const { fetchStatus, getJson } = useFetch();
    
    const [ allIngredients, setAllIngredients ] = useState<IngredientType[]>([]);
    const [ ingredients, setIngredients ] = useState<IngredientType[]>([]);
    const [ filterText, setFilterText ] = useState<string>("");

    function onFilterTextChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        setFilterText(event.target.value);
        filterResults(event.target.value, allIngredients);
    }

    const filterResults = useCallback((filter: string, ingredients: IngredientType[]) => {
        if(!filter) {
            setIngredients(ingredients);
        }
        else {
            setIngredients(ingredients.filter(x => x.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1));
        }
    }, []);

    function onIngredientDeleted(ingredient: IngredientType) {
        setAllIngredients((prev) => {
            return removeIngredient(prev, ingredient);
        });

        setIngredients((prev) => {
            return removeIngredient(prev, ingredient);
        });
    }

    useEffect(() => {
        getJson("ingredient/search", {}).then((result: any) => {
            const results = IngredientHelper.ingredientsFromApi(result.ingredients);
            results.sort((a, b) => Util.getTextSortOrder(a.name, b.name));
            setAllIngredients(results);
        }).catch((error) => {
            console.log(error);
        });
    }, [getJson]);
    
    useEffect(() => {
        filterResults(filterText, allIngredients);
    }, [allIngredients, filterResults, filterText]);

    if(!fetchStatus.isComplete()) {
        return <LoadIndicator />
    }

    let ingredientsContent: JSX.Element | JSX.Element[] = (
        <tr>
            <td colSpan={5} className="text-center text-warning">
                No ingredients found.
            </td>
        </tr>
    );

    if(ingredients.length > 0) {
        ingredientsContent = (
            ingredients.map(ingredient => <Ingredient key={ingredient.id} ingredient={ingredient} onIngredientDeleted={onIngredientDeleted}/>)
        );
    }

    return (
        <div className="d-flex flex-grow-1">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <div>
                        <h3 className="text-center">My ingredients</h3>
                    </div>
                </div>
                <div className={"card-body " + styles.ingredientListViewStyle}>
                    <div className="mb-2">
                        <input type="search" className="form-control" placeholder={"Filter ingredients..."} value={filterText} onChange={onFilterTextChanged} />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Default unit</th>
                                <th scope="col">Default department</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {ingredientsContent}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

interface IIngredientProps {
    ingredient: IngredientType,
    onIngredientDeleted(ingredient: IngredientType): void
}

function Ingredient(props: IIngredientProps) {

    const { fetchStatus, postJson } = useFetch();
    const [ errors, setErrors ] = useState<string[]>([]);
    const [ originalIngredient, setOriginalIngredient] = useState<IngredientType>(props.ingredient);
    const [ ingredient, setIngredient ] = useState<IngredientType>(props.ingredient);

    function onSaveIngredient(e: React.MouseEvent<HTMLButtonElement>) {
        let validationErrors = IngredientHelper.validateIngredient(ingredient);
        setErrors(validationErrors);
        if(validationErrors.length > 0) {
            return;
        }

        let apiIngredient = IngredientHelper.convertIngredientForApi(ingredient);
        postJson("ingredient/update", apiIngredient).then(result => {
            // Success
            setOriginalIngredient(ingredient);
        }).catch(error => {
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
        }).finally(() => {
            
        });
    }

    function onDeleteIngredient(e: React.MouseEvent<HTMLButtonElement>) {
        // eslint-disable-next-line no-restricted-globals
        if(!confirm("Are you sure?"))
        {
            return;
        }

        setErrors([]);
        
        postJson("ingredient/delete/" + ingredient.id).then(result => {
            // Success
            props.onIngredientDeleted(ingredient);
        }).catch(error => {
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
        }).finally(() => {
            
        });
    }

    function onNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.currentTarget.value;
        setIngredient((prev) => {
            let ing = {...prev};
            ing.name = newValue;
            return ing;
        });
    }

    function onDefaultUnitChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let newValue = e.currentTarget.value;
        setIngredient((prev) => {
            let ing = {...prev};
            ing.defaultUnit = newValue;
            return ing;
        });
    }

    function onDefaultDepartmentChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        let newValue = e.currentTarget.value;
        setIngredient((prev) => {
            let ing = {...prev};
            ing.defaultDepartment = newValue;
            return ing;
        });
    }

    let showLoadIndicator = false;
    if(!fetchStatus.isComplete()) {
        showLoadIndicator = true;
    }
    
    let isModifiedIngredient = false;
    if(ingredient.name !== originalIngredient.name || ingredient.defaultUnit !== originalIngredient.defaultUnit || ingredient.defaultDepartment !== originalIngredient.defaultDepartment) {
        isModifiedIngredient = true;
    }

    return (
        <React.Fragment>
            <tr key={ingredient.id} className={showLoadIndicator ? "table-secondary" : ""}>
                <td className="align-middle">{showLoadIndicator ? <LoadIndicator/> : (isModifiedIngredient ? <i className="bi-slash-circle text-warning"></i> : <i className="bi-check-circle text-success"></i>)}</td>
                <td className="border-end align-middle"><button type="button" className="btn btn-secondary" disabled={showLoadIndicator || !isModifiedIngredient} onClick={onSaveIngredient}>Save</button></td>
                <td>
                    <InputControl type="text" maxLength={50} name="name" value={ingredient.name} onChange={onNameChanged} disabled={showLoadIndicator} />
                </td>
                <td className="align-middle"><UnitOfMeasureDropdown isReadOnly={showLoadIndicator} value={ingredient.defaultUnit} onChange={onDefaultUnitChanged} /></td>
                <td className="align-middle"><GroceryDepartmentDropdown isReadOnly={showLoadIndicator} value={ingredient.defaultDepartment} onChange={onDefaultDepartmentChanged}/></td>
                <td className="border-start align-middle"><button type="button" className="btn btn-danger" disabled={showLoadIndicator} onClick={onDeleteIngredient}>Delete</button></td>
            </tr>
            <tr className={errors.length < 1 ? "visually-hidden" : ""}>
                <td></td>
                <td></td>
                <td colSpan={4}><ErrorList errors={errors}/></td>
            </tr>
        </React.Fragment>
    )
}