

import React, { ReactNode, useState } from 'react';
import { ApiErrorInfo } from '../../helpers/errorInfo';
import ErrorList from '../utilities/errorList';
import { useOverlay } from "../../providers/OverlayProvider";
import InputControl from "./inputControl";

export interface IFormConfig {
    headerContent?: ReactNode,
    footerContent?: ReactNode,
    fields?: IFormField[],
    validate?(fieldsAreValid: boolean): string[],
    onSubmit(): Promise<any>,
    onSuccess?(): void,
    //TODO2: Should this be required?
    onFailure?(): void
}

interface IFormField {
    name: string,
    label: string,
    type: "text" | "password" | "email",
    value: string,
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void,
    required?: boolean,
    readOnly?: boolean,
    autoFocus?: boolean,
    maxLength?: number
    // errors: string[],
    // setErrors(errors: string[]): void,
    // validate(): boolean
}

interface IFormProps {
    config: IFormConfig
}

export default function Form(props: IFormProps) {
    let config = props.config;

    const {showLoadingIndicatorOverlay} = useOverlay();

    const [errors, setErrors] = useState<string[]>([]);

    function onSubmit(event: React.FormEvent<HTMLFormElement>){
        // This will only be called if standard validation is successful.

        showLoadingIndicatorOverlay(true);
        event.preventDefault();

        //TODO2: Errors in this control are a bad design or the consumer of this control must be aware that it cannot re-create the component
        //       after a waiting action is performed (ex: You cannot return <loadIndicator/> during a query and set an error in this form because the error will only exist
        //       on a component that has been destroyed and a new one has taken its place)
        // Clear generic errors
        setErrors([]);

        // Clear field specific errors & validate individual fields
        let fieldsAreValid = true;
        // if(config && config.fields && config.fields.length > 0) {
        //     for(var i = 0; i < config.fields.length; i++) {
        //         let field = config.fields[i];
        //         if(field.setErrors && typeof field.setErrors === "function") {
        //             field.setErrors([]);
        //         }

        //         if(field.validate && typeof field.validate === "function") {
        //             if(!field.validate()) {
        //                 fieldsAreValid = false;
        //             }
        //         }
        //     }
        // }

        // Client side validation
        let validatePromise: Promise<string[]> = Promise.resolve([]);
        if(config.validate && typeof config.validate === "function") {
            validatePromise = Promise.resolve(config.validate(fieldsAreValid));
        }

        validatePromise.then((validationErrors) => {
            if(fieldsAreValid && validationErrors.length === 0) {
                let isSuccess = false;
                Promise.resolve(config.onSubmit()).then(() => {
                    isSuccess = true;
                }).catch((error) => {
                    if(error instanceof ApiErrorInfo)
                    {
                        setErrors(error.errors);

                        // if(error.fieldErrors.length > 0 &&
                        //     config && config.fields && config.fields.length > 0) {
    
                        //     for(var i = 0; i < error.fieldErrors.length; i++) {
                        //         let fieldError = error.fieldErrors[i];
    
                        //         for(var j = 0; j < config.fields.length; j++) {
                        //             let field = config.fields[j];
                        //             if(fieldError.fieldName === field.name && field.setErrors && typeof field.setErrors === "function") {
                        //                 field.setErrors(fieldError.errors);
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }).finally(() => {
                    showLoadingIndicatorOverlay(false);
                    if(isSuccess && config && typeof config.onSuccess === "function") {
                        config.onSuccess();
                    }
                    else if(!isSuccess && config && typeof config.onFailure === "function") {
                        config.onFailure();
                    }
                });
            }
            else {
                setErrors(validationErrors);
            }
        });
    }

    let formHeader = null;
    if(config && config.headerContent) {
        formHeader = (
            <div className="mb-4">
                {config.headerContent}
            </div>
        )
    }
    
    let formBody = null;
    if(config && config.fields && config.fields.length > 0) {
        formBody = config.fields.map(field => {
            return (
                <div key={field.name} className="mb-4">
                    <InputControl label={field.label} autoFocus={field.autoFocus} type={field.type} name={field.name} value={field.value} onChange={field.onChange} required={field.required} readOnly={field.readOnly} maxLength={field.maxLength} />
                    {/* <ErrorList errors={field.errors}></ErrorList> */}
                </div>
            );
            
        });
    }

    let formFooter = null;
    if(config && config.footerContent) {
        formFooter = config.footerContent;
    }

    return (
        <form onSubmit={onSubmit} className="needs-validation">
            {formHeader}
            {formBody}
            <ErrorList errors={errors}></ErrorList>
            {formFooter}
        </form>
    );
}