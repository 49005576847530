
import React, { useCallback, useState } from 'react';
import Util from "../helpers/util";
import Accordion from "./controls/accordion";
import BusyIndicator from "./controls/busyIndicator";
import { ApiRecipeUsageType, RecipeUsageType } from "../helpers/recipeHelper";
import useFetch from "../hooks/useFetch";
import AsyncDeleteButton from "./controls/asyncDeleteButton";
import { useAuth } from "../providers/AuthProvider";

interface IRecipeUsageProps {
    recipeId: string
}

export default function RecipeUsage(props: IRecipeUsageProps) {
    const [history, setHistory] = useState<RecipeUsageType[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    let { user } = useAuth();
    let { fetchStatus, getJson } = useFetch();
    let { postJson } = useFetch();
    
    
    const onExpandToggled = useCallback(async (isExpanded: boolean) => {
        if(!isLoaded && isExpanded) {
            await getJson(`recipe/userusagehistory/${props.recipeId}`).then((result: any) => {
                if(result && result.usageHistory) {
                    setHistory((result.usageHistory as ApiRecipeUsageType[]).map((x: any): RecipeUsageType => {
                        return {
                            id: x.id,
                            recipeId: x.recipeId,
                            usageDate: Util.parseDateStrict(x.usageDate)
                        };
                    }));
                }
            }).finally(() => {
                setIsLoaded(true);
            })
        }
    }, [getJson, isLoaded, props.recipeId]);
    
    const recipeId = Util.parseInt(props.recipeId);
    if(!user.id || recipeId <= 0) {
        return null;
    }

    return (
        <Accordion id={"usageHistory"} label="My usage history" onExpandToggled={onExpandToggled}>
            <BusyIndicator showIndicator={!fetchStatus.isComplete()}>
                {history.length > 0 ? 
                    <ul className="list-group">{history.map(recipeUsage => {
                            const usageDate = recipeUsage.usageDate.toDateString();
                            function deleteUsage(): Promise<boolean> {
                                return postJson("recipe/removeusage/" + recipeUsage.id).then(() => true).catch(() => false);
                            }

                            function onFinalized(): void {
                                setHistory(prev => {
                                    let state = [...prev];
                                    const idx = state.findIndex(x => x.id === recipeUsage.id);
                                    if(idx >= 0) {
                                        state.splice(idx, 1);
                                    }
                                    return state;
                                });
                            }

                            return (
                                <li className="list-group-item" key={usageDate}>
                                    <span>{usageDate}</span>
                                    <AsyncDeleteButton performDelete={deleteUsage} onFinalized={onFinalized}></AsyncDeleteButton>
                                </li>
                            );
                        })}
                </ul> :
                <div className={`text-center ${!fetchStatus.isComplete() ? "visually-hidden" : ""}`}>
                    <span className="text-warning">There is no usage history for this recipe.</span>
                </div>}
            </BusyIndicator>
        </Accordion>
    );
}