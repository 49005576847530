import RecipeHelper, { RecipeIngredientType } from "../helpers/recipeHelper";

export interface IRecipeSearchState {
    searchText: string,
    searchTags: string[],
    ingredientSearchText: string,
    searchIngredients: string[],
    isMatchOnAllIngredients: boolean,
    isTypeAheadSearchRunning: boolean,
    quickAddErrors: string[],
    quickAddInProgress: boolean
}

export const enum RECIPESEARCH_ACTION_TYPE {
    SEARCH_TEXT_CHANGED,
    SEARCH_TAGS_CHANGED,
    INGREDIENT_SEARCH_TEXT_CHANGED,
    INGREDIENT_ADDED,
    INGREDIENT_REMOVED,
    IS_MATCH_ON_ALL_CHANGED,
    QUICK_ADD_INITIATED,
    QUICK_ADD_COMPLETED
}

export type RecipeSearchActionType =
    | { type: RECIPESEARCH_ACTION_TYPE.SEARCH_TEXT_CHANGED, payload: string }
    | { type: RECIPESEARCH_ACTION_TYPE.SEARCH_TAGS_CHANGED, payload: string[] }
    | { type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_SEARCH_TEXT_CHANGED, payload: string }
    | { type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_ADDED, payload: string }
    | { type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_REMOVED, payload: string }
    | { type: RECIPESEARCH_ACTION_TYPE.IS_MATCH_ON_ALL_CHANGED, isMatchOnAll: boolean }
    | { type: RECIPESEARCH_ACTION_TYPE.QUICK_ADD_INITIATED }
    | { type: RECIPESEARCH_ACTION_TYPE.QUICK_ADD_COMPLETED, payload: { errors: string[] }}

export function RecipeSearchReducer(state: IRecipeSearchState, action: RecipeSearchActionType): IRecipeSearchState {
    const s = {...state};
    switch(action.type)
    {
        case RECIPESEARCH_ACTION_TYPE.SEARCH_TEXT_CHANGED:
            s.searchText = action.payload;
            s.quickAddErrors = [];
            return s;
        case RECIPESEARCH_ACTION_TYPE.SEARCH_TAGS_CHANGED:
            s.searchTags = [...action.payload];
            return s;
        case RECIPESEARCH_ACTION_TYPE.INGREDIENT_SEARCH_TEXT_CHANGED:
            s.ingredientSearchText = action.payload;
            return s;
        case RECIPESEARCH_ACTION_TYPE.INGREDIENT_ADDED:
            s.searchIngredients = [...s.searchIngredients];
            if(s.searchIngredients.indexOf(action.payload) === -1) {
                s.searchIngredients.push(action.payload);
            }
            s.ingredientSearchText = "";
            return s;
        case RECIPESEARCH_ACTION_TYPE.INGREDIENT_REMOVED:
            s.searchIngredients = [...s.searchIngredients];
            const idx = s.searchIngredients.indexOf(action.payload);
            if(idx !== -1) {
                s.searchIngredients.splice(idx, 1);
            }
            return s;
        case RECIPESEARCH_ACTION_TYPE.IS_MATCH_ON_ALL_CHANGED:
            s.isMatchOnAllIngredients = action.isMatchOnAll;
            return s;
        case RECIPESEARCH_ACTION_TYPE.QUICK_ADD_INITIATED:
            s.quickAddErrors = [];
            s.quickAddInProgress = true;
            return s;
        case RECIPESEARCH_ACTION_TYPE.QUICK_ADD_COMPLETED:
            s.quickAddErrors = action.payload.errors;
            if(s.quickAddErrors.length === 0) {
                s.searchText = "";
            }
            s.quickAddInProgress = false;
            return s;
        default:
            const unhandledActionType: never = action;
            throw new Error(`Unexpected action type used in reducer: ${unhandledActionType}`);
    }

}