
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmPasswordReset from '../components/account/confirmPasswordReset';
import PasswordReset from '../components/account/passwordReset';
import SmallForm from '../components/utilities/smallForm';


export default function ResetPassword() {
    let navigate = useNavigate();
    let location = useLocation();

    const [email, setEmail] = useState(location.state?.email || "");
    const [isResetConfirmed, setIsResetConfirmed] = useState(false);

    // If password is reset successfully bring user back to sign in - they will have to enter the password again
    function onPasswordResetSuccess() {
        navigate("/account/sign-in", {
            state: {
                email: email,
                message: "Password has been successfully reset. Please sign in with your new password to continue."
            }
        });
    }

    let body = isResetConfirmed ? (
        <PasswordReset email={email} onPasswordResetSuccess={onPasswordResetSuccess} onRetryClick={() => setIsResetConfirmed(false)} />
    ) : (
        <ConfirmPasswordReset email={email} setEmail={setEmail} onConfirm={() => setIsResetConfirmed(true)} />
    );

    return (
        <SmallForm headerText="Reset password">
            {body}
        </SmallForm>
    );
}
