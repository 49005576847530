import React, { useState } from 'react';
import Form, { IFormConfig } from '../controls/form';
import { useAuth } from '../../providers/AuthProvider';

type PasswordResetType = {
    email: string,
    onPasswordResetSuccess(): void,
    onRetryClick(e: any): void
};

export default function PasswordReset(props: PasswordResetType & React.PropsWithChildren) {

    if(!props.email) {
        console.error("props.email not defined");
    }

    if(!props.onPasswordResetSuccess) {
        console.error("props.onPasswordResetSuccess not defined");
    }

    if(!props.onRetryClick) {
        console.error("props.onRetryClick not defined");
    }

    const { resetPassword } = useAuth();
    const [securityCode, setSecurityCode] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");


    let formConfig: IFormConfig = {
        fields: [{
                name: "email",
                label: "Email",
                type: "email",
                value: props.email,
                readOnly: true
            }, {
                name: "securityCode",
                label: "Code",
                type: "text",
                value: securityCode,
                required: true,
                onChange: (e: any) => setSecurityCode(e.target.value)
            }, {
                name: "newPassword1",
                label: "New password",
                type: "password",
                value: newPassword1,
                required: true,
                onChange: (e: any) => setNewPassword1(e.target.value)
            }, {
                name: "password2",
                label: "Confirm new password",
                type: "password",
                value: newPassword2,
                required: true,
                onChange: (e: any) => setNewPassword2(e.target.value)
            }
        ],
        validate: function() {
            let errors = [];
            if(newPassword1 !== newPassword2)
            {
                errors.push("Passwords do not match!");
            }
            return errors;
        },
        onSubmit: function() {
            return resetPassword(props.email, securityCode, newPassword1).then(() => {
                props.onPasswordResetSuccess();
            });
        },
        headerContent: (
            <p>A security code was sent to the specified email address. Enter the security code here and define a new password.</p>
        ),
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Reset password</button>
                </div>
                <div className="row justify-content-center mb-2">
                    <button type="button" className="btn btn-link" onClick={props.onRetryClick}>Click here if you didn't receive an email, the email address was entered incorrect, or to request a new verification code</button>
                </div>
            </div>
        )
    };

    return (
        <Form config={formConfig} />
    );
}