import React from 'react';
import styles from '../utilities/smallForm.module.scss';

type SmallFormProps = {
    headerText: string
} & React.PropsWithChildren

export default function SmallForm(props: SmallFormProps) {
    return (
		<div className={"h-50 d-flex flex-column justify-content-center"}>
            <div className="d-flex justify-content-center">
                <div className={"card col-sm-12 col-md-8 col-xl-6 shadow-lg mb-5 bg-body rounded " + styles.smallFormWidth}>
                    <div className="card-header">
                        <h3 className="text-center">{props.headerText}</h3>
                    </div>
                    <div className={"card-body " + styles.smallFormBodyCard}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
	)
}