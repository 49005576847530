
import React from 'react';

type RecipeTagGroup = {
    groupName: string,
    tags: string[]
}

const tagGroups: RecipeTagGroup[] = [
    {
        groupName: "Course",
        tags: ["Appetizer/Snack", "Beverage", "Dessert", "Side"]
    },
    // {
    //     groupName: "Protein",
    //     tags: ["Beef", "Chicken", "Pork"]
    // }
];

interface IRecipeTagsV2Props {
    selectedTags: Array<string>,
    updateTags(tags: Array<string>): void
}

export default function RecipeTags(props: IRecipeTagsV2Props) {

    function addTags(tags: string[]) {
        let newTags = [...props.selectedTags];
        tags.forEach(tag => {
            if(props.selectedTags.indexOf(tag) === -1) {
                newTags.push(tag);
            }
        });
        props.updateTags(newTags);
    }

    function removeTags(tags: string[]) {
        let newTags = [...props.selectedTags];
        tags.forEach(tag => {
            newTags.splice(newTags.indexOf(tag), 1);
        });
        props.updateTags(newTags);
    }

    function onTagSelectionChanged(tag: string, isChecked: boolean) {
        isChecked ? addTags([tag]) : removeTags([tag]);
    }

    return (
        // <div className={"row justify-content-center"}>
        <div className={"row"}>
            <div className="col-sm-6 mb-2">
                <h6>{tagGroups[0].groupName}</h6>
                {tagGroups[0].tags.map((tag: string) => {
                    const key = tagGroups[0].groupName + "_" + tag;
                    return (
                        <div key={key}>
                            <input type="checkbox" id={key} className="me-1 mb-2" checked={props.selectedTags.indexOf(tag) !== -1} onChange={(e) => onTagSelectionChanged(tag, e.target.checked)}/>
                            <label htmlFor={key}>{tag}</label>
                        </div>
                    );
                })}
            </div>
            {/* <div className="col-sm-6 mb-2">
                <h6>{tagGroups[1].groupName}</h6>
                {tagGroups[1].tags.map((tag: string) => {
                    const key = tagGroups[0].groupName + "_" + tag;
                    return (
                        <div key={key}>
                            <input type="checkbox" id={key} className="me-1 mb-2" checked={props.selectedTags.indexOf(tag) !== -1} onChange={(e) => onTagSelectionChanged(tag, e.target.checked)}/>
                            <label htmlFor={key}>{tag}</label>
                        </div>
                    );
                })}
            </div> */}
        </div>
    );
}