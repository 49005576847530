
import React from 'react';
import IngredientQuantity from "../../components/controls/ingredientQuantity";
import UnitOfMeasureDropdown from "../../components/controls/unitOfMeasureDropdown";
import { GroceryListItemType } from "../../helpers/groceryListHelper";
import GroceryDepartmentDropdown from "../../components/controls/groceryDepartmentDropdown";
import { IngredientAutoCompleteTextbox } from "../controls/ingredientAutoCompleteTextbox";
import IngredientHelper, { IIngredientSearchResult } from "../../helpers/ingredientHelper";
import DeleteButton from "../controls/deleteButton";


/*
Grocery item can be defined in 3 ways
--Manually defining text, uom, department, etc
--recipe_ingredient will be defined and that will carry the 'ingredient' as a property
--ingredient can be selected using the ingredient autocomplete search box
*/


type GroceryListItemPropType = {
    isShoppingView: boolean,
    showUpArrow: boolean,
    showDownArrow: boolean,
    groceryListItem: GroceryListItemType,
    onItemChanged(oldItem: GroceryListItemType, newItem: GroceryListItemType): void
    removeItem(item: GroceryListItemType): void
    moveItemUp(item: GroceryListItemType): void
    moveItemDown(item: GroceryListItemType): void
} & React.PropsWithChildren


export function GroceryListItem(props: GroceryListItemPropType) {

    function onItemTextChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.text = value;
        item.ingredient = null;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyWholeChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityWhole = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyNumeratorChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityNumerator = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyDenominatorChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityDenominator = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onUoMChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.unit = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onDepartmentChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.department = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onSelectedChanged(isSelected: boolean) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.selected = isSelected;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onSearchResultSelected(selectedIngredient: IIngredientSearchResult)
    {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.text = selectedIngredient.name;
        item.unit = selectedIngredient.defaultUnit;
        item.department = selectedIngredient.defaultDepartment;
        var ing = IngredientHelper.initIngredient();
        ing.name = selectedIngredient.name;
        item.ingredient = ing;
        props.onItemChanged(props.groceryListItem, item);
    }

    let isFromRecipe = !!props.groceryListItem.recipeIngredientId;
    let text = props.groceryListItem.text;
    let qtyWhole = props.groceryListItem.quantityWhole;
    let qtyNumerator = props.groceryListItem.quantityNumerator;
    let qtyDenominator = props.groceryListItem.quantityDenominator;
    let unit = props.groceryListItem.unit;
    let department = props.groceryListItem.department;

    if(props.isShoppingView) {
        return (
            <div className="d-flex">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" checked={props.groceryListItem.selected} aria-label="Checkbox for selecting item" onChange={(e) => onSelectedChanged(e.target.checked)}/>
                </div>
                <div className="">
                    <span>{text}{qtyWhole || qtyNumerator || qtyDenominator ? " - " : ""}</span>
                    <IngredientQuantity isReadOnly={true} qtyWhole={qtyWhole} qtyNumerator={qtyNumerator} qtyDenominator={qtyDenominator} />
                    {unit === "each" ? null : <UnitOfMeasureDropdown isReadOnly={true} value={unit} /> }
                    {isFromRecipe && props.groceryListItem.recipeIngredient?.recipe?.name ? (<span className="fw-light"> ({props.groceryListItem.recipeIngredient?.recipe?.name})</span>) : ""}
                </div>
            </div>
        );
    }

    return (
        <tr data-isfromrecipe={isFromRecipe}>
            <td className="visually-hidden">
                <span>{props.groceryListItem.index}</span>
            </td>
            <td>
                <DeleteButton onClick={() => props.removeItem(props.groceryListItem)}/>
                <button type="button" className={"btn p-0" + (!props.showUpArrow ? " invisible" : "")} onClick={() => props.moveItemUp(props.groceryListItem)}><i className="bi-arrow-up"></i></button>
                <button type="button" className={"btn p-0" + (!props.showDownArrow ? " invisible" : "")} onClick={() => props.moveItemDown(props.groceryListItem)}><i className="bi-arrow-down"></i></button>
            </td>
            <td >
                <IngredientAutoCompleteTextbox isReadOnly={isFromRecipe}
                                                includeRecipeAsIngredient={false}
                                                value={text}
                                                onTextChanged={onItemTextChanged}
                                                onSelectItem={onSearchResultSelected}
                                                />
            </td>
            <td>
                { (isFromRecipe) ? <div>&nbsp;<span>{props.groceryListItem.recipeIngredient?.recipe?.name}</span></div> : <span>&nbsp;</span> }
            </td>
            <td >
                <IngredientQuantity isReadOnly={isFromRecipe} qtyWhole={qtyWhole} qtyNumerator={qtyNumerator} qtyDenominator={qtyDenominator} onQtyWholeChanged={(e) => onQtyWholeChanged(e.currentTarget.value)} onQtyNumeratorChanged={(e) => onQtyNumeratorChanged(e.currentTarget.value)} onQtyDenominatorChanged={(e) => onQtyDenominatorChanged(e.currentTarget.value)} />
            </td>
            <td >
                <UnitOfMeasureDropdown isReadOnly={isFromRecipe} value={unit} onChange={(e) => onUoMChanged(e.currentTarget.value)} />
            </td>
            <td >
                <GroceryDepartmentDropdown isReadOnly={false} value={department} onChange={(e) => onDepartmentChanged(e.currentTarget.value)} />
            </td>
        </tr>
    );
}