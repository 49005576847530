
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form, { IFormConfig } from '../controls/form';
import { useAuth } from '../../providers/AuthProvider';
import SmallForm from '../utilities/smallForm';
import { useOverlay } from "../../providers/OverlayProvider";

export default function ChangePassword() {
    let navigate = useNavigate();
    let location = useLocation();
    let navigateToAfterCompletion = location.state?.from?.pathname || "/account";

    const { changePassword } = useAuth();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const { showSuccessIndicatorOverlay } = useOverlay();

    function cancel() {
        // Could use '-1' as param to go back to the last page, but user may not be in the application.
        navigate(navigateToAfterCompletion);
    }

    let formConfig: IFormConfig = {
        fields: [{
                name: "currentPassword",
                label: "Current password",
                type: "password",
                value: currentPassword,
                required: true,
                onChange: (e: any) => setCurrentPassword(e.target.value)
            }, {
                name: "password1",
                label: "New password",
                type: "password",
                value: newPassword1,
                required: true,
                maxLength: 30,
                onChange: (e: any) => setNewPassword1(e.target.value)
            }, {
                name: "password2",
                label: "Confirm new password",
                type: "password",
                value: newPassword2,
                required: true,
                onChange: (e: any) => setNewPassword2(e.target.value)
            }
        ],
        validate: function() {
            let errors = [];
            if(newPassword1 !== newPassword2)
            {
                errors.push("Passwords do not match!");
            }
            return errors;
        },
        onSubmit: function() {
            return changePassword(currentPassword, newPassword1);
        },
        onSuccess: function() {
            showSuccessIndicatorOverlay("Password updated successfully!", () => {
                navigate(navigateToAfterCompletion);
            });
        },
        footerContent: (
            <div className="row justify-content-center">
                <div className="row justify-content-center mb-2">
                    <button type="submit" className="btn btn-primary col-sm-8">Change password</button>
                </div>
                <div className="row justify-content-center mb-2">
                    <button type="button" onClick={cancel} className="btn btn-secondary col-sm-8">Cancel</button>
                </div>
            </div>
        )
    };

    return (
        <SmallForm headerText="Change password">
            <Form config={formConfig} />
        </SmallForm>
    );
}
