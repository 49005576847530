
import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch';
import LoadIndicator from '../components/loadIndicator';
import GroceryListHelper, { ApiGroceryListType, GroceryListItemType, GroceryListType } from "../helpers/groceryListHelper";
import ErrorList from "../components/utilities/errorList";
import { ApiErrorInfo } from "../helpers/errorInfo";
import Util from "../helpers/util";
import styles from "../components/groceryList/groceryList.module.scss";
import { GroceryListing } from "../components/groceryList/groceryListing";
import { useOverlay } from "../providers/OverlayProvider";
import LocalStorage from "../helpers/localStorage";

function isEmptyItem(item: GroceryListItemType): boolean {
    return (!item.text || !item.text.trim()) && !item.recipeIngredientId;
}

function removeEmptyItems(items: GroceryListItemType[]) {
    for(var i = 0; i < items.length; i++) {
        if(isEmptyItem(items[i])) {
            items.splice(i, 1);
            i--;
        }
    }
}

function isGroceryListEmpty(groceryList: GroceryListType) {
    var isEmpty = true;
    for(let item of groceryList.items) {
        if(!isEmptyItem(item)) {
            isEmpty = false;
            break;
        }
    }
    return isEmpty;
}

function getGroceryListWithEmptyItem(groceryList: GroceryListType) {
    let lastItem = groceryList.items[groceryList.items.length - 1];
    if(!lastItem || !isEmptyItem(lastItem)) {
        let newItem = GroceryListHelper.initGroceryListItem(groceryList.items.length);
        groceryList.items.push(newItem);
    }
    return groceryList;
}

function reIndexItems(items: GroceryListItemType[]) {
    // Update indexing
    for(var i = 0; i < items.length; i++) {
        items[i].index = String(i);
    }
}

export default function GroceryListView() {
    const { fetchStatus, getJson, postJson } = useFetch();
    const [ groceryList, setGroceryList] = useState<GroceryListType>(() => {
        let groceryList = GroceryListHelper.initGroceryList();
        return getGroceryListWithEmptyItem(groceryList);
    });

    const [showOnlyUnselectedItems, setShowOnlyUnselectedItems] = useState<boolean>(() => {
        return LocalStorage.getLocalStorageBool(LocalStorage.KEY_GL_SHOWONLYUNSELECTEDITEMS);
    });
    const [isShoppingView, setIsShoppingView] = useState<boolean>(() => {
        return LocalStorage.getLocalStorageBool(LocalStorage.KEY_GL_ISSHOPPINGVIEW);
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const { showSuccessIndicatorOverlay } = useOverlay();

    const [saveErrors, setSaveErrors] = useState<string[]>([]);

    function onGroceryListItemsChanged(groceryListItems: GroceryListItemType[]) {
        setGroceryList((oldValue) => {
            let updatedList = {...oldValue};
            updatedList.items = groceryListItems;
            reIndexItems(updatedList.items);
            return isShoppingView ? updatedList : getGroceryListWithEmptyItem(updatedList);
        });
    }

    function onGroceryListItemChanged(oldItem: GroceryListItemType, newItem: GroceryListItemType) {
        setGroceryList((old) => {
            let updatedList = {...old};
            let modifiedItemIdx = updatedList.items.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, oldItem));
            if(modifiedItemIdx !== -1) {
                updatedList.items.splice(modifiedItemIdx, 1, newItem);
            }
            return isShoppingView ? updatedList : getGroceryListWithEmptyItem(updatedList);
        });
    }

    function clearAllItems() {
        onGroceryListItemsChanged([]);
    }

    function onShowOnlyUnselectedItemsChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setShowOnlyUnselectedItems(event.target.checked);
        LocalStorage.setLocalStorageBool(LocalStorage.KEY_GL_SHOWONLYUNSELECTEDITEMS, event.target.checked);
    }
    
    function onIsShoppingViewChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.checked) {
            setGroceryList((old) => {
                let updatedList = {...old};
                removeEmptyItems(updatedList.items);
                reIndexItems(updatedList.items);
                return updatedList;
            });
        }
        else {
            setGroceryList((old) => {
                let updatedList = {...old};
                reIndexItems(updatedList.items);
                return getGroceryListWithEmptyItem(updatedList);
            });
        }
        setIsShoppingView(event.target.checked);
        LocalStorage.setLocalStorageBool(LocalStorage.KEY_GL_ISSHOPPINGVIEW, event.target.checked);
    }

    function sortByDepartment() {
        setGroceryList((old) => {
            let updatedList = {...old};
            let sortedItems = updatedList.items;
            let sortOrder = GroceryListHelper.getDefaultDepartmentSortOrder();
            let nextAddIdx = 0;
            sortOrder.forEach(department => {
                for(var i = nextAddIdx; i < sortedItems.length; i++) {
                    let item = sortedItems[i];
                    if(!isEmptyItem(item) && item.department === department && i >= nextAddIdx) {
                        var removedItem = sortedItems.splice(i, 1);
                        sortedItems.splice(nextAddIdx, 0, removedItem[0]);
                        nextAddIdx++;
                        i--;
                        continue;
                    }
                }
            });
            
            updatedList.items = sortedItems;
            reIndexItems(updatedList.items);
            return isShoppingView ? updatedList : getGroceryListWithEmptyItem(updatedList);
        });
    }

    function save() {
        setSaveErrors([]);

        let data = {...groceryList};
        //TODO2: When cloning objects, need to be sure every sub-property (of type object) is also cloned so the original is not modified
        data.items = [...groceryList.items];
        removeEmptyItems(data.items);
        
        reIndexItems(data.items);
        let apiList = GroceryListHelper.convertToApiJson(data);

        postJson("grocerylist/save", apiList).then(() => {
			showSuccessIndicatorOverlay();
            setRefreshData(true);
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setSaveErrors(error.errors);
            }
        });
    }

    useEffect(() => {
        if(refreshData) {
            getJson("grocerylist/").then((groceryListResult: ApiGroceryListType) => {
                let initializedGL = GroceryListHelper.initGroceryListFromApi(groceryListResult) || GroceryListHelper.initGroceryList();
                let groceryList = initializedGL;
                
                // If not in shopping view show an empty item
                if(!isShoppingView) {
                    groceryList = getGroceryListWithEmptyItem(initializedGL);
                    // If grocery list is empty don't allow user to be in 'shopping view'
                    if(isGroceryListEmpty(groceryList)) {
                        setIsShoppingView(false);
                    }
                }

                // Sort by item index
                groceryList.items.sort((a, b) => (Util.parseInt(a.index) < Util.parseInt(b.index)) ? -1 : 1);
                setGroceryList(groceryList);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setRefreshData(false);
            });
        }
    }, [getJson, isShoppingView, refreshData]);

    if(!fetchStatus.isComplete()) {
        return <LoadIndicator />
    }

    return (
        <div className="d-flex flex-grow-1">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <h3 className="text-center">Grocery list</h3>
                </div>
                <div className={"card-body " + styles.groceryListStyle}>
                    <div className="row">
                        <div className="col-sm-6 text-center">
                            <input type="checkbox" id="showOnlyUnselectedItems" className="me-1 mb-2" checked={showOnlyUnselectedItems} onChange={onShowOnlyUnselectedItemsChanged}/>
                            <label htmlFor="showOnlyUnselectedItems">Show only unselected items</label>
                            {showOnlyUnselectedItems
                                ?
                                <span className="fs-smaller fst-italic">{(" - (" + groceryList.items.filter(x => x.selected).length) + "/" + groceryList.items.length + " item(s) selected)"}</span>
                                : null
                            }
                        </div>
                        <div className="col-sm-6 text-center">
                            <input type="checkbox" id="shoppingView" className="me-1 mb-2" checked={isShoppingView} onChange={onIsShoppingViewChanged}/>
                            <label htmlFor="shoppingView">Shopping view</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <button type="button" onClick={clearAllItems} className="btn btn-secondary mb-2 w-100">Clear all</button>
                        </div>
                        <div className="col-sm-4">
                            <button type="button" onClick={sortByDepartment} className="btn btn-secondary mb-2 w-100">Sort by department</button>
                        </div>
                        <div className="col-sm-4">
                            <button type="button" onClick={save} className="btn btn-secondary mb-2 w-100">Save</button>
                        </div>
                    </div>
                    <ErrorList errors={saveErrors} />
                    <GroceryListing showOnlyUnselectedItems={showOnlyUnselectedItems} isShoppingView={isShoppingView} groceryList={groceryList} onGroceryListItemChanged={onGroceryListItemChanged} onGroceryListItemsChanged={onGroceryListItemsChanged}/>
                </div>
                <div className="card-footer">
                    <div className="float-end">
                        <button type="button" onClick={save} className="btn btn-secondary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}