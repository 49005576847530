import React, { createContext, useCallback, useState } from 'react';
import SuccessIndicator from "../components/successIndicator";
import LoadIndicator from "../components/loadIndicator";

interface IOverlayProviderContext {
    showSuccessIndicatorOverlay(messsage?: string, onOk?: () => void): void,
    showLoadingIndicatorOverlay(show: boolean): void,
    setOverlayContent(overlayContent: JSX.Element | null): void
}

type OverlayProviderPropsType = {
    
} & React.PropsWithChildren;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverlayContext = createContext<IOverlayProviderContext | undefined>(undefined);


// https://www.w3schools.com/howto/howto_css_overlay.asp
// https://stackoverflow.com/questions/46659136/how-can-i-cover-with-overlay-specific-div
export function OverlayProvider(props: OverlayProviderPropsType) {
    const [overlayContent, setOverlayContent] = useState<JSX.Element | null>(null);

    const showSuccessIndicatorOverlay = useCallback((messsage?: string, onOk?: () => void) => {
        setOverlayContent(<SuccessIndicator onOk={() => {
            setOverlayContent(null);
            if(onOk && typeof onOk === "function") {
                onOk();
            }
        }} message={messsage}/>);
    }, []);

    const showLoadingIndicatorOverlay = useCallback((show: boolean) => {
        setOverlayContent(show ? <LoadIndicator/> : null);
    }, []);

    // Return the provider with child contents inside of it. Children will access the nearest 'provided' context
    // and will be able to utilize anything passed in the 'value' prop.
    return (
        <OverlayContext.Provider value={{ setOverlayContent, showSuccessIndicatorOverlay, showLoadingIndicatorOverlay }}>
            <div className={"overlay" + (!overlayContent ? " d-none" : "")}>
                {overlayContent}
            </div>
            {props.children}
        </OverlayContext.Provider>
    );
}


// Define custom hook to use in consuming components
export function useOverlay(){
    const context = React.useContext(OverlayContext);
    if(context === undefined) {
        throw new Error("context is not defined. Be sure the component is wrapped in the provider before using it");
    }
    return context;
}