import React, { useCallback } from "react"
import AsyncStatusButton from "../controls/asyncStatusButton"
import RecipeHelper from "../../helpers/recipeHelper"
import useFetch from "../../hooks/useFetch"
import { useOverlay } from "../../providers/OverlayProvider"
import ModalDatePicker from "../controls/ModalDatePicker"

type MarkAsConsumedBtnPropType = {
    recipeId: string,
    btnClassName?: string,
    additionalBtnClassName?: string
} & React.PropsWithChildren

export default function MarkAsConsumedBtn(props: MarkAsConsumedBtnPropType) {
    let { postJson } = useFetch();
    let { setOverlayContent } = useOverlay();

    const onClick = useCallback((): Promise<boolean> => {
        return new Promise((resolve) => {
            let onClose = function() {
                setOverlayContent(null);
                resolve(false);
            };

            let onOk = function(date: Date) {
                RecipeHelper.markAsUsed(postJson, props.recipeId, date).then(result => {
                    setOverlayContent(null);
                    resolve(result);
                });
            }

            setOverlayContent((
                <ModalDatePicker onClose={onClose} onOk={onOk} />
            ));
        });
    }, [postJson, props, setOverlayContent]);
    return (
        <AsyncStatusButton btnClassName={props.btnClassName || ""} displayClassName="" onClick={onClick}><i className="bi-robot"></i> Mark as consumed</AsyncStatusButton>
    )
}