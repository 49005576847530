import { ApiErrorInfo } from "./errorInfo"
import IngredientHelper, { ApiIngredientType, IngredientType } from "./ingredientHelper"
import { ApiOwner, Owner } from "./ownerHelper"
import Util from "./util"

export type ApiRecipeIngredientType = {
    id: string,
    index?: string,
    ingredient: ApiIngredientType | null,
    ingredientRecipe: ApiRecipeType | null,
    note: string,
    quantityDenominator: string,
    quantityNumerator: string,
    quantityWhole: string,
    groupName: string,
    text: string,
    unit: string,
    recipe?: ApiRecipeType | null
}

type ApiIngredientGroupType = {
    groupName: string,
    ingredients: ApiRecipeIngredientType[]
}

type ApiRecipeInstructionType = {
    id: string,
    index?: string,
    text: string
}

type ApiInstructionGroupType = {
    groupName: string,
    instructions: ApiRecipeInstructionType[]
}

export type ApiRecipeType = {
    id: string,
    owner?: ApiOwner,
    name: string,
    totalTime?: string,
    prepTime: string,
    cookTime: string,
    inactiveTime: string,
    imported?: boolean,
    sourceUrl: string,
    creationDate: string,
    ingredientGroups: ApiIngredientGroupType[],
    instructionGroups: ApiInstructionGroupType[],
    tags: string[]
}

//TODO2: Convert this to interface & create a class.
//      This allows the 'isRecipe' to be a function and will be able to reference 'ingredientRecipe'.
//      Right now you need to define 'isRecipe' after creating a RecipeIngredient (if you don't remember this it's trouble)
export type RecipeIngredientType = {
    id: string,
    index: string,
    groupName: string,
    isHeader: boolean,
    isRecipe: boolean,
    text: string,
    qtyWhole: string,
    qtyNumerator: string,
    qtyDenominator: string,
    unit: string,
    note: string,
    ingredient: IngredientType | null,
    ingredientRecipe: RecipeType | null,
    recipe: RecipeType | null
}

export type RecipeInstructionType = {
    id: string,
    index: string,
    groupName: string,
    isHeader: boolean,
    text: string
}

export type RecipeType = {
    id: string,
    owner: Owner,
    name: string,
    totalTime: number,
    prepTime: number,
    cookTime: number,
    inactiveTime: number,
    imported: boolean,
    creationDate: Date | null,
    sourceUrl: string,
    ingredients: RecipeIngredientType[],
    instructions: RecipeInstructionType[],
    tags: string[]
}

type RecipeIngredientGroupType = {
    name: string,
    groupNameIdx: number,
    ingredients: RecipeIngredientType[]
};

type RecipeInstructionGroupType = {
    name: string,
    groupNameIdx: number,
    instructions: RecipeInstructionType[]
};

class FieldError {
    fieldName: string | null;
    error: string;
    constructor(fieldName: string | null, error: string) {
        this.fieldName = fieldName;
        this.error = error;
    }
}

class ItemError extends FieldError {
    itemIndex: number;
    constructor(index: number, fieldName: string | null, error: string) {
        super(fieldName, error);
        this.itemIndex = index;
    }
}

type RecipeValidationResultType = {
    fieldErrors: FieldError[],
    ingredientErrors: ItemError[],
    instructionErrors: ItemError[],
    hasErrors: boolean
}

type RecipeSearchCriteriaType = {
    name?: string,
    owner?: string,
    ownerId?: string,
    tags?: string[],
    ingredients?: string[],
    isMatchOnAllIngredients?: boolean,
    pageNumber: number,
    pageSize: number
}

export type RecipeUsageType = {
    id: string,
    recipeId: string,
    usageDate: Date
}

export type ApiRecipeUsageType = {
    id: string,
    recipeId: string,
    usageDate: string
}

export default class RecipeHelper {
    static initRecipe(): RecipeType {
        let recipe: RecipeType = {
            id: "",
            name: "",
            totalTime: 0,
            prepTime: 0,
            cookTime: 0,
            inactiveTime: 0,
            imported: false,
            sourceUrl: "",
            creationDate: null,
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            ingredients: [],
            instructions: [],
            tags: []
        };
        return recipe;
    }

    static initRecipeIngredient(index: number): RecipeIngredientType {
        return {
            id: "",
            index: String(index),
            groupName: "",
            isHeader: false,
            isRecipe: false,
            text: "",
            qtyWhole: "",
            qtyNumerator: "",
            qtyDenominator: "",
            unit: "",
            note: "",
            ingredient: IngredientHelper.initIngredient(),
            ingredientRecipe: RecipeHelper.initRecipe(),
            recipe: null
        };
    }

    static initRecipeInstruction(index: number): RecipeInstructionType {
        return {
            id: "",
            index: String(index),
            groupName: "",
            isHeader: false,
            text: ""
        };
    }

    static initApiRecipe(): ApiRecipeType {
        let recipe: ApiRecipeType = {
            id: "",
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            name: "",
            totalTime: "",
            prepTime: "",
            cookTime: "",
            inactiveTime: "",
            imported: false,
            sourceUrl: "",
            creationDate: "",
            ingredientGroups: [],
            instructionGroups: [],
            tags: []
        };
        return recipe;
    }

    static recipesFromApi(dataRecipes: ApiRecipeType[]): RecipeType[] {
        let recipes: RecipeType[] = [];
        if(dataRecipes.length > 0) {
            dataRecipes.forEach((x: ApiRecipeType) => {
                recipes.push(RecipeHelper.recipeFromApi(x));
            });
        }
        return recipes;
    }

    static recipeFromApi(dataRecipe: ApiRecipeType) {
        let recipe: RecipeType = {
            id: dataRecipe.id,
            name: dataRecipe.name,
            totalTime: Util.secondsToMinutes(Util.parseInt(dataRecipe.totalTime)),
            prepTime: Util.secondsToMinutes(Util.parseInt(dataRecipe.prepTime)),
            cookTime: Util.secondsToMinutes(Util.parseInt(dataRecipe.cookTime)),
            inactiveTime: Util.secondsToMinutes(Util.parseInt(dataRecipe.inactiveTime)),
            imported: dataRecipe.imported as boolean,
            sourceUrl: Util.parseStr(dataRecipe.sourceUrl),
            creationDate: Util.parseDate(dataRecipe.creationDate),
            owner: {
                alias: Util.parseStr(dataRecipe.owner?.alias),
                ownerId: Util.parseStr(dataRecipe.owner?.ownerId),
                ownerId2: Util.parseStr(dataRecipe.owner?.ownerId2)
            },
            ingredients: [],
            instructions: [],
            tags: []
        };

        // Recipe ingredient/instructions are indexed to include header. When sent to server they are indexed without consideration to group headings.
        // This is kind of a workaround to have a unique key when these are put into a <table>
        // Server should just take care of indexing...
        let idx = 0;
        if(dataRecipe.ingredientGroups && dataRecipe.ingredientGroups.length > 0) {
            // Parse ingredientGroups (categorizes the appropriate ingredients under their respective headers)
            for(let i = 0; i < dataRecipe.ingredientGroups.length; i++) {
                const recipeIngredientGroup = dataRecipe.ingredientGroups[i];
                const groupName = Util.parseStr(recipeIngredientGroup.groupName);
                if(groupName) {
                    // Create the 'header' ingredient record (not actually an ingredient but needs to be in the list of ingredients)
                    recipe.ingredients.push({
                        isHeader: true,
                        index: String(idx),
                        text: groupName
                    } as RecipeIngredientType);
                    idx++;
                }
                
                // Create the actual ingredients
                for(let j = 0; j < recipeIngredientGroup.ingredients.length; j++) {
                    const recipeIngredient = recipeIngredientGroup.ingredients[j];
                    let ingredient = RecipeHelper.recipeIngredientFromApi(recipeIngredient);
                    ingredient.index = String(idx);
                    recipe.ingredients.push(ingredient);
                    idx++;
                }
            }
        }

        idx = 0;
        if(dataRecipe.instructionGroups && dataRecipe.instructionGroups.length > 0) {
            for(let i = 0; i < dataRecipe.instructionGroups.length; i++) {
                const recipeInstructionGroup = dataRecipe.instructionGroups[i];
                const groupName = Util.parseStr(recipeInstructionGroup.groupName);

                // Define group headers
                if(groupName) {
                    recipe.instructions.push({
                        isHeader: true,
                        index: String(idx),
                        text: groupName
                    } as RecipeInstructionType);
                    idx++;
                }

                for (let j = 0; j < recipeInstructionGroup.instructions.length; j++) {
                    const recipeInstruction = recipeInstructionGroup.instructions[j];
                    
                    recipe.instructions.push({
                        id: recipeInstruction.id,
                        index: String(idx),
                        groupName: groupName,
                        isHeader: false,
                        text: Util.parseStr(recipeInstruction.text)
                    });
                    idx++;
                }
            }
        }

        if(dataRecipe.tags && dataRecipe.tags.length > 0) {
            dataRecipe.tags.forEach((tag: string) => {
                recipe.tags.push(tag);
            });
        }

        return recipe;
    }

    static recipeIngredientFromApi(recipeIngredient: ApiRecipeIngredientType): RecipeIngredientType {
        return {
            id: recipeIngredient.id,
            index: Util.parseStr(recipeIngredient.index),
            groupName: Util.parseStr(recipeIngredient.groupName),
            isHeader: false,
            isRecipe: recipeIngredient.ingredientRecipe != null,
            text: Util.parseStr(recipeIngredient.text),
            qtyWhole: Util.parseStr(recipeIngredient.quantityWhole),
            qtyNumerator: Util.parseStr(recipeIngredient.quantityNumerator),
            qtyDenominator: Util.parseStr(recipeIngredient.quantityDenominator),
            unit: Util.parseStr(recipeIngredient.unit),
            note: Util.parseStr(recipeIngredient.note),
            ingredient: (recipeIngredient.ingredient != null) ? IngredientHelper.ingredientFromApi(recipeIngredient.ingredient) : null,
            ingredientRecipe: (recipeIngredient.ingredientRecipe != null) ? RecipeHelper.recipeFromApi(recipeIngredient.ingredientRecipe) : null,
            recipe: (recipeIngredient.recipe != null) ? RecipeHelper.recipeFromApi(recipeIngredient.recipe) : null
        };
    }

    static convertRecipeToApiJson(recipe: RecipeType): ApiRecipeType {
        let data: ApiRecipeType = {
            id: String(recipe.id),
            name: recipe.name,
            prepTime: String(Util.minutesToSeconds(recipe.prepTime)),
            cookTime: String(Util.minutesToSeconds(recipe.cookTime)),
            inactiveTime: String(Util.minutesToSeconds(recipe.inactiveTime)),
            sourceUrl: recipe.sourceUrl,
            creationDate: "",
            ingredientGroups: [],
            instructionGroups: [],
            tags: []
        };
        
        let ingredientsByGroup = this.convertIngredientsForApi(recipe.ingredients);
        let instructionsByGroup = this.convertInstructionsForApi(recipe.instructions);

        // Ingredients
        for(let i = 0; i < ingredientsByGroup.length; i++) {
            const ingredientGroup = ingredientsByGroup[i];
            let dataIngredientGroup: ApiIngredientGroupType = {
                groupName: ingredientGroup.name,
                ingredients: []
            };

            for (let j = 0; j < ingredientGroup.ingredients.length; j++) {
                const ingredient = ingredientGroup.ingredients[j];
                ingredient.groupName = ingredientGroup.name;
                let apiIngredient = RecipeHelper.convertRecipeIngredientForApi(ingredient);
                dataIngredientGroup.ingredients.push(apiIngredient);
            }
            data.ingredientGroups.push(dataIngredientGroup);
        }

        // Instructions
        for(let i = 0; i < instructionsByGroup.length; i++) {
            const instructionGroup = instructionsByGroup[i];
            let dataInstructionGroup: ApiInstructionGroupType = {
                groupName: instructionGroup.name,
                instructions: []
            };

            for (let j = 0; j < instructionGroup.instructions.length; j++) {
                const instruction = instructionGroup.instructions[j];
                let dataInstruction: ApiRecipeInstructionType = {
                    id: String(instruction.id),
                    text: instruction.text
                };

                dataInstructionGroup.instructions.push(dataInstruction);
            }
            data.instructionGroups.push(dataInstructionGroup);
        }

        data.tags = recipe.tags;
        return data;
    }

    static convertRecipeIngredientForApi(recipeIngredient: RecipeIngredientType): ApiRecipeIngredientType {
        return {
            id: String(recipeIngredient.id),
            groupName: recipeIngredient.groupName,
            text: recipeIngredient.text,
            quantityWhole:recipeIngredient.qtyWhole,
            quantityNumerator:recipeIngredient.qtyNumerator,
            quantityDenominator:recipeIngredient.qtyDenominator,
            unit: recipeIngredient.unit,
            note: recipeIngredient.note,
            ingredient: recipeIngredient.ingredient ? IngredientHelper.convertIngredientForApi(recipeIngredient.ingredient) : null,
            ingredientRecipe: recipeIngredient.ingredientRecipe ? RecipeHelper.convertRecipeToApiJson(recipeIngredient.ingredientRecipe) : null
        };
    }

    static convertIngredientsForApi(ingredients: RecipeIngredientType[]): RecipeIngredientGroupType[] {
        let currentGroup: RecipeIngredientGroupType | null = null;
        let ingredientsByGroup: RecipeIngredientGroupType[] = [];

        for(let i = 0; i < ingredients.length; i++) {
            const ingredientItem = ingredients[i];
            if(ingredientItem.isHeader) {
                currentGroup = {
                    name: ingredientItem.text ?? "",
                    groupNameIdx: i,
                    ingredients: []
                };

                ingredientsByGroup.push(currentGroup);
            }
            else {
                if(currentGroup === null) {
                    currentGroup = {
                        name: "",
                        groupNameIdx: -1,
                        ingredients: []
                    };

                    ingredientsByGroup.push(currentGroup);
                }

                currentGroup.ingredients.push(ingredientItem);
            }
        }

        return ingredientsByGroup;
    }

    static convertInstructionsForApi(instructions: RecipeInstructionType[]): RecipeInstructionGroupType[] {
        let currentGroup: RecipeInstructionGroupType | null = null;
        let instructionsByGroup: RecipeInstructionGroupType[] = [];

        for(let i = 0; i < instructions.length; i++) {
            const instructionItem = instructions[i];
            if(instructionItem.isHeader) {
                currentGroup = {
                    name: instructionItem.text ?? "",
                    groupNameIdx: i,
                    instructions: []
                };

                instructionsByGroup.push(currentGroup);
            }
            else {
                if(currentGroup === null) {
                    currentGroup = {
                        name: "",
                        groupNameIdx: -1,
                        instructions: []
                    };

                    instructionsByGroup.push(currentGroup);
                }

                currentGroup.instructions.push(instructionItem);
            }
        }

        return instructionsByGroup;
    }

    static validateIngredients(ingredients: RecipeIngredientType[]): ItemError[] {
        // Headers must have at least one item
        // Qty fields must be integers and > 0
        // Qty numerator cannot be >= qty denominator
        // Qty numerator cannot exist if qty denominator exists

        let errors: ItemError[] = [];

        const isValidQty = function(value: any) {
            if(value || value === 0) {
                if(isNaN(value)) {
                    return false;
                }
                else {
                    return Number.isInteger(value) && value > 0;
                }
            }
            return true;
        };

        let currentGroup: RecipeIngredientGroupType | null = null;
        let ingredientsByGroup: RecipeIngredientGroupType[] = [];

        for(let i = 0; i < ingredients.length; i++) {
            const ingredientItem = ingredients[i];
            if(ingredientItem.isHeader) {
                if(!ingredientItem.text || !ingredientItem.text.trim()) {
                    errors.push(new ItemError(i, "text", "Group heading cannot be empty"));
                }

                // New header/group. Check the previous group to ensure there are items in it
                if(currentGroup !== null && currentGroup.ingredients.length === 0) {
                    errors.push(new ItemError(currentGroup.groupNameIdx, null, "Group must contain at least one ingredient"));
                }

                currentGroup = {
                    name: ingredientItem.text ?? "",
                    groupNameIdx: i,
                    ingredients: []
                };

                ingredientsByGroup.push(currentGroup);
            }
            else {
                // ignore ingredients with no value set
                if(ingredientItem.text && ingredientItem.text.trim()) {

                    // Groups are not required, so first or only group may not have a header.
                    if(currentGroup === null) {
                        currentGroup = {
                            name: "",
                            groupNameIdx: -1,
                            ingredients: []
                        };

                        ingredientsByGroup.push(currentGroup);
                    }

                    let qtyWhole = Number.parseFloat(ingredientItem.qtyWhole) || "";
                    let qtyNumerator = Number.parseFloat(ingredientItem.qtyNumerator) || "";
                    let qtyDenominator = Number.parseFloat(ingredientItem.qtyDenominator) || "";

                    // Validate ingredient
                    let invalidFractionalQty = false;

                    if(!isValidQty(qtyWhole)) {
                        errors.push(new ItemError(i, "qtyWhole", "Invalid number for quantity"));
                    }

                    if(!isValidQty(qtyNumerator)) {
                        invalidFractionalQty = true;
                        errors.push(new ItemError(i, "qtyNumerator", "Invalid number for fractional quantity numerator"));
                    }

                    if(!isValidQty(qtyDenominator)) {
                        invalidFractionalQty = true;
                        errors.push(new ItemError(i, "qtyDenominator", "Invalid number for fractional quantity denominator"));
                    }

                    if(qtyNumerator && !qtyDenominator) {
                        errors.push(new ItemError(i, null, "Invalid fractional quantity, missing denominator"));
                    }
                    else if(!qtyNumerator && qtyDenominator) {
                        errors.push(new ItemError(i, null, "Invalid fractional quantity, missing numerator"));
                    }

                    if(!invalidFractionalQty && qtyNumerator && qtyDenominator && qtyNumerator >= qtyDenominator) {
                        errors.push(new ItemError(i, "qtyNumerator", "Invalid fractional quantity, numerator larger than or equal to denominator"));
                    }

                    currentGroup.ingredients.push(ingredientItem);
                }
            }

            // Last item in the list. Check that there are ingredients in the group
            if(i === ingredients.length - 1) {
                if(currentGroup !== null && currentGroup.ingredients.length === 0) {
                    errors.push(new ItemError(currentGroup.groupNameIdx, null, "Group must contain at least one ingredient"));
                }
            }
        }

        return errors;
    }

    static validateInstructions(instructions: RecipeInstructionType[]): ItemError[] {
        // Headers must have at least one item
        let errors: ItemError[] = [];
        let currentGroup: RecipeInstructionGroupType | null = null;
        let instructionsByGroup = [];

        for(let i = 0; i < instructions.length; i++) {
            const instructionItem = instructions[i];
            if(instructionItem.isHeader) {
                if(!instructionItem.text || !instructionItem.text.trim()) {
                    errors.push(new ItemError(i, "text", "Group heading cannot be empty"));
                }

                // New header/group. Check the previous group to ensure there are items in it
                if(currentGroup !== null && currentGroup.instructions.length === 0) {
                    errors.push(new ItemError(currentGroup.groupNameIdx, null, "Group heading must contain at least one instruction"));
                }

                currentGroup = {
                    name: instructionItem.text ?? "",
                    groupNameIdx: i,
                    instructions: []
                };

                instructionsByGroup.push(currentGroup);
            }
            else {
                // ignore instructions with no value set
                if(instructionItem.text && instructionItem.text.trim()) {
                    if(currentGroup === null) {
                        currentGroup = {
                            name: "",
                            groupNameIdx: -1,
                            instructions: []
                        };

                        instructionsByGroup.push(currentGroup);
                    }

                    currentGroup.instructions.push(instructionItem);
                }
            }

            // Last item in the list. Check that there are instructions in the group
            if(i === instructions.length - 1) {
                if(currentGroup !== null && currentGroup.instructions.length === 0) {
                    errors.push(new ItemError(currentGroup.groupNameIdx, null, "Group heading must contain at least one instruction"));
                }
            }
        }

        return errors;
    }

    static validate(recipe: RecipeType): RecipeValidationResultType {

        let result: RecipeValidationResultType = {
            fieldErrors: [],
            ingredientErrors: [],
            instructionErrors: [],
            hasErrors: false
        };

        if(!recipe.name || !recipe.name.trim()) {
            result.fieldErrors.push(new FieldError("name", "required"));
        }

        result.ingredientErrors = this.validateIngredients(recipe.ingredients);
        result.instructionErrors = this.validateInstructions(recipe.instructions);
        result.hasErrors = result.fieldErrors.length > 0 || result.ingredientErrors.length > 0 || result.instructionErrors.length > 0;
        return result;
    }

    static recipeValidationResultsAsGenericErrors(recipeValidationResult: RecipeValidationResultType): string[] {
        let err: string[] = [];
        recipeValidationResult.fieldErrors.forEach((e) => {
            err.push(e.fieldName + ": " + e.error);
        });

        recipeValidationResult.ingredientErrors.forEach((e) => {
            let msg = "Ing '" + e.itemIndex + "' ";
            if(e.fieldName) {
                msg += e.fieldName + ": ";
            }
            msg += e.error;
            err.push(msg);
        });

        recipeValidationResult.instructionErrors.forEach((e) => {
            let msg = "Ins '" + e.itemIndex + "' ";
            if(e.fieldName) {
                msg += e.fieldName + ": ";
            }
            msg += e.error;
            err.push(msg);
        });

        return err;
    }

    static isSameRecipe(recipe1: RecipeType, recipe2: RecipeType): boolean {
        // Ignore case
        return recipe1 && recipe2 && recipe1.name.toLowerCase() === recipe2.name.toLowerCase();
    }

    static markAsUsed(postJson: (url: string, jsonData: any) => Promise<any>, recipeId: string, usageDate: Date): Promise<boolean> {
        let data: ApiRecipeUsageType = {
            id: "",
            recipeId: recipeId,
            usageDate: Util.toIso8601DateString(usageDate)
        };

        return postJson("recipe/markasused", data).then(() => {
            return true;
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
            }
            return false;
        });
    }
}