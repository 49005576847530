import React, { createContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';

interface IProtectedRouteContext {
    
}

type ProtectedRoutePropsType = {
    allowUnverified?: boolean
} & React.PropsWithChildren;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProtectedRouteContext = createContext<IProtectedRouteContext | undefined>(undefined);

// Taken from here:
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
export function ProtectedRouteProvider(props: ProtectedRoutePropsType) {
    const { user } = useAuth();
    let location = useLocation();

    let routeChild = props.children;

    //TODO2: Jwt update is triggering the 'user' state update which is triggering unnecessary renders
    if(!user.id) {
        // User is not known and must sign in before accessing protected routes
        routeChild = <Navigate to={"/account/sign-in"} state={{ from: location}} />;
    }
    else if(!user.emailVerified && !props.allowUnverified) {
        // User must verify email address before accessing protected routes
        routeChild = <Navigate to={"/account/verify"} state={{ from: location}} />;
    }

    // Return the provider with child contents inside of it. Children will access the nearest 'provided' context
    // and will be able to utilize anything passed in the 'value' prop.
    return (
        <ProtectedRouteContext.Provider value={{}}>
            {routeChild}
        </ProtectedRouteContext.Provider>
    );
}


// Define custom hook to use in consuming components
export function useProtectedRoute(){
    const context = React.useContext(ProtectedRouteContext);
    if(context === undefined) {
        throw new Error("context is not defined. Be sure the component is wrapped in the provider before using it");
    }
    return context;
}