import GroceryListHelper from "../../helpers/groceryListHelper";

type GroceryDepartmentDropdownPropType = {
    isReadOnly?: boolean,
    value: string,
    onChange?(event: React.ChangeEvent<HTMLSelectElement>): void
} & React.PropsWithChildren

export default function GroceryDepartmentDropdown(props: GroceryDepartmentDropdownPropType) {

    if(props.isReadOnly) {
        return <span>&nbsp;{props.value}</span>;
    }

    return (
        <select className="form-select" value={props.value} onChange={props.onChange}>
            <option value=""></option>
            {GroceryListHelper.getAvailableDepartments().map(department => 
                <option key={department} value={department}>{department}</option>
            )}
        </select>
    )
}