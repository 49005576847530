import RecipeHelper, { ApiRecipeType, RecipeType } from "../helpers/recipeHelper";


type RecipeSearchCriteriaType = {
    name?: string,
    owner?: string,
    ownerId?: string,
    tags?: string[],
    ingredients?: string[],
    isMatchOnAllIngredients?: boolean,
    pageNumber: number,
    pageSize: number
}

//TODO3: all static functions is bad design
export default class RecipeService {

    // https://howtodoinjava.com/typescript/difference-between-record-and-map/
    private static recipeCache = new Map<string, Promise<any>>();

    private static buildCacheKey(searchCriteria: RecipeSearchCriteriaType): string {
        return JSON.stringify(searchCriteria);
    }

    // TODO: make all recipe add/update/delete come from this class and then make the following function private
    static onRecipeChange(): void {
        this.recipeCache.clear();
    }
 
    static get(getJson: (url: string) => Promise<any>, recipeId: string): Promise<any> {
        //TODO3: Any considerations that this class is only relevant until the next page navigation/load? Is there a better way to cache across page loads?
        //TODO3: Cache?
        //      https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
        return getJson("recipe/" + recipeId);
    }

    static add(postJson: (url: string, data: ApiRecipeType) => Promise<any>, data: ApiRecipeType): Promise<any> {
        return postJson("recipe/add", data).then((result: any) => {
            RecipeService.recipeCache.clear();
            return result;
        });
    }

    static edit(postJson: (url: string, data: ApiRecipeType) => Promise<any>, data: ApiRecipeType): Promise<any> {
        return postJson("recipe/edit", data).then((result: any) => {
            RecipeService.recipeCache.clear();
            return result;
        });
    }

    static delete(postJson: (url: string) => Promise<any>, recipeId: string): Promise<any> {
        return postJson("recipe/delete/" + recipeId).then((result: any) => {
            RecipeService.recipeCache.clear();
            return result;
        });
    }

    //TODO2: Find another way to reference 'getJson'
    static search(getJson: (url: string, data: any) => Promise<any>, searchCriteria: RecipeSearchCriteriaType): Promise<{
        recipes: RecipeType[],
        cntTotalRecords: number
    }> {
        searchCriteria = {
            name: searchCriteria.name || "",
            owner: searchCriteria.owner || "",
            ownerId: searchCriteria.ownerId || "",
            tags: searchCriteria.tags || [],
            ingredients: searchCriteria.ingredients || [],
            isMatchOnAllIngredients: searchCriteria.isMatchOnAllIngredients || false, // Default match on 'any' ingredient
            pageNumber: searchCriteria.pageNumber,
            pageSize: searchCriteria.pageSize
        };

        //TODO: This is dangerous? The page may not ever actually refresh
        //      - Look at cookbook page 2
        //      - Log out & back in
        //      - Navigate to page 2, did it need to load?
        const key = RecipeService.buildCacheKey(searchCriteria);
        if(!this.recipeCache.has(key)){
            let promise = getJson("recipe/search", searchCriteria);
            this.recipeCache.set(key, promise);
        }

        return this.recipeCache.get(key)!.then((result: any) => {
            return {
                recipes: RecipeHelper.recipesFromApi(result.recipes),
                cntTotalRecords: result.totalRecordCount
            }
        });
    }
}