import React, { useCallback, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './App.scss';
import ErrorList from './components/utilities/errorList';
import Fetch from './helpers/fetch';
import { useAuth } from './providers/AuthProvider';
import { OverlayProvider } from "./providers/OverlayProvider";

export default function App() {
    console.log("process.env.NODE_ENV: " + process.env.NODE_ENV);
    console.log("process.env.REACT_APP_API_BASE_URL: " + process.env.REACT_APP_API_BASE_URL);

    return (
        <div className="h-100 d-flex flex-column">
            <header className="">
                <NavBar />
            </header>
            <div className="d-flex flex-grow-1 container-fluid">
                {/* flex-grow-1 here means 'allow the 1 index element to be the one that grows while the other are a width defined by their contents.
                    This allows the app content to be directly in the center
                */}
                <div className="row flex-grow-1">
                    <div className="col-md-6 col-lg-2 order-2 order-lg-1">
                        <div className="my-5">
                            {/* Advertisement column*/}
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-8 order-1 order-lg-2">
                        <main role="main" className="main-content">
                            <GlobalErrorDisplay />
                            <OverlayProvider>
                                <div className="container px-0">
                                    {/* Outlet is where content for a given sub-path will go. Similar to WPF ContentControl */}
                                    <Outlet />
                                </div>
                            </OverlayProvider>
                        </main>
                    </div>
                    <div className="col-md-6 col-lg-2 order-3 order-lg-3">
                        <div className="my-5">
                            {/* Advertisement column */}
                        </div>
                    </div>
                </div>
            </div>
            <footer className="shadow-lg bg-body">
                <div className="text-center">
                    <span>Copyright © 2023 - RecipeRolo. All Right Reserved.</span>
                </div>
            </footer>
        </div>
    );
}

function NavBar() {

    const { userId, userEmail } = useAuth();
    let [isNavbarExpanded, setIsNavbarExpanded] = useState<boolean>(false);

    const toggleNavbarExpanded = useCallback(() => {
        setIsNavbarExpanded((currentState) => !currentState);
    }, []);

    const collapseNavbar = useCallback(() => {
        setIsNavbarExpanded(false);
    }, []);

    let accountNavItem = userId ? (
        <li className="nav-item dropdown">
          <button className="btn nav-link dropdown-toggle" type="button" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            Account ({userEmail})
          </button>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/ingredient-list/" onClick={collapseNavbar}>Manage ingredients</Link></li>
            <li><Link className="dropdown-item" to="/account/" onClick={collapseNavbar}>Settings</Link></li>
            <li><Link className="dropdown-item" to="/account/sign-out" onClick={collapseNavbar}>Sign out</Link></li>
          </ul>
        </li>
    ) : null;

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-lg bg-body rounded navbar-min-height">
            <div className="container">
                <Link className="navbar-brand" to="/">RecipeRolo</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavbarExpanded}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={(isNavbarExpanded ? "" : "collapse") + " navbar-collapse"} id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item"><Link className="nav-link" to="/" onClick={collapseNavbar}>Home</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/cookbook" onClick={collapseNavbar}>Cookbook</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/meal-plan" onClick={collapseNavbar}>Meal plan</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/grocery-list" onClick={collapseNavbar}>Grocery list</Link></li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item"><Link className="nav-link" to="/support" onClick={collapseNavbar}>Support/Help</Link></li>
                        {userId ? "" : <li className="nav-item"><Link className="nav-link" to="/account/sign-in" onClick={collapseNavbar}>Sign in</Link></li>}
                        {userId ? "" : <li className="nav-item"><Link className="nav-link" to="/account/sign-up" onClick={collapseNavbar}>Sign up</Link></li>}
                        {accountNavItem}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

function GlobalErrorDisplay() {
    let [errors, setErrors] = useState<string[]>([]);

    Fetch.OnUnexpectedError = function() {
        //TODO2: To reload automatically: window.location.reload()
        setErrors(["An unexpected error occurred - service may be temporarily unavailable. Refresh the page to try again."]);
    }

    return <ErrorList errors={errors} />
}