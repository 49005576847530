import React from 'react';
import CloseButton from "../controls/closeButton";

type ModalDialogProps = {
    isSuccessLayout?: boolean,
    isErrorLayout?: boolean,
    titleContent?: any,
    onOk?(): void,
    onClose(): void,
} & React.PropsWithChildren

export default function ModalDialog(props: ModalDialogProps) {
    let titleContent = props.titleContent || (props.isErrorLayout ? "Error(s)" : "Information");

    if(props.isSuccessLayout && props.isErrorLayout) {
        throw new Error("ModalDialog-success and error layout cannot both be set");
    }

    function onClickOk() {
        if(props.onOk) {
            props.onOk();
        }
    }

    let modalContentClass = "modal-content";
    let okButtonClass = "btn px-3";
    if(props.isSuccessLayout) {
        modalContentClass += " border-success";
        okButtonClass += " btn-success";
    }
    else if(props.isErrorLayout) {
        modalContentClass += " border-danger";
    }
    else {
        okButtonClass += " border";
    }

    let okBtn = null;
    if(props.onOk) {
        okBtn = (
            <button autoFocus type="button" className={okButtonClass} onClick={onClickOk}>Ok</button>
        );
    }

    return (
		// 'modal-dialog' will make the dialog show where you place it. 'modal' will cover the whole screen
        <div className="modal fade show d-block bg-dark bg-opacity-50" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="false">
			<div className="modal-dialog">
				<div className={modalContentClass}>
					<div className="modal-header">
						<h5 className="modal-title">{titleContent}</h5>
                        <CloseButton onClick={props.onClose} />
					</div>
					<div className="modal-body text-center">
						{props.children}
					</div>
					<div className="modal-footer">
						{okBtn}
					</div>
				</div>
			</div>
		</div>
	)
}