import IngredientHelper from "../../helpers/ingredientHelper";

type UnitOfMeasureDropdownPropType = {
    isReadOnly?: boolean,
    value: string,
    onChange?(event: React.ChangeEvent<HTMLSelectElement>): void
} & React.PropsWithChildren

export default function UnitOfMeasureDropdown(props: UnitOfMeasureDropdownPropType) {
    
    if(props.isReadOnly) {
        return <span>&nbsp;{props.value}</span>;
    }

    return (
        <select className="form-select" value={props.value} onChange={props.onChange}>
            <option value=""></option>
            {IngredientHelper.getAvailableUoM().map(unit => 
                <optgroup key={unit.type} label={"By " + unit.type}>
                    {unit.values.map(value => 
                        <option key={value} value={value}>{value}</option>
                    )}
                </optgroup>
            )}
        </select>
    )
}