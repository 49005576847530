import React from "react"

type DeleteButtonPropType = {
    onClick(event: React.MouseEvent<HTMLButtonElement>): void
} & React.PropsWithChildren

export default function DeleteButton(props: DeleteButtonPropType) {

    return (
        <button type="button" className="btn p-0" onClick={props.onClick}><i className="bi-trash3"></i></button>
    )
}