

//Very helpful tut
//https://www.youtube.com/watch?v=Ke90Tje7VS0

//https://create-react-app.dev/docs/importing-a-component/
//https://exploringjs.com/es6/ch_modules.html
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

//Styling
//https://reactjs.org/docs/faq-styling.html
//https://getbootstrap.com/docs/5.0/getting-started/introduction/


function SignOut() {
    let navigate = useNavigate();
    const { userId, signOut } = useAuth();
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    console.log("signout - user id: " + userId);

    useEffect(() => {
        if(userId) {
            console.log("user id set");
            signOut().then(() => {
                setIsLoggedOut(true);
                navigate("/account/sign-in");
            });
        }
        else {
            console.log("user id not set");
            setIsLoggedOut(true);
        }
    }, [signOut, userId, navigate]);

    return (
        <div>
            {isLoggedOut ? "Logged out successfully." : "Logging you out..."}
        </div>
    );
}

export default SignOut;