


export default class LocalStorage {
    static KEY_GL_SHOWONLYUNSELECTEDITEMS = "GroceryList_ShowOnlyUnselectedItems";
    static KEY_GL_ISSHOPPINGVIEW = "GroceryList_IsShoppingView";
    static KEY_REC_ISMATCHONALLINGREDIENTS = "RecipeSearch_IsMatchOnAllIngredients";

    static getLocalStorageBool(key: string): boolean {
        let value = localStorage.getItem(key);
        return value === "TRUE";
    }
    
    static setLocalStorageBool(key: string, value: boolean): void {
        localStorage.setItem(key, value ? "TRUE" : "FALSE");
    }    
}