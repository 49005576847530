

//Very helpful tut
//https://www.youtube.com/watch?v=Ke90Tje7VS0

//https://create-react-app.dev/docs/importing-a-component/
//https://exploringjs.com/es6/ch_modules.html
import React, { useCallback, useReducer, useState } from 'react';
import { useLocation, useNavigate } from "react-router";
import { RecipeAutoCompleteTextbox } from "../components/controls/recipeAutoCompleteTextbox";
import RecipeHelper, { RecipeIngredientType, RecipeType } from "../helpers/recipeHelper";
import InputControl from "../components/controls/inputControl";
import BusyIndicator from "../components/controls/busyIndicator";
import IngredientHelper, { IngredientType } from "../helpers/ingredientHelper";
// import Table from './controls/table';


//Styling
//https://reactjs.org/docs/faq-styling.html
//https://getbootstrap.com/docs/5.0/getting-started/introduction/


export default function Home() {
    let navigate = useNavigate();
    let location = useLocation();

    const [searchText, setSearchText] = useState("");

    function onSelectItem(selectedItem: RecipeType) {
        navigate("/recipe/edit/" + selectedItem.id, {
            state: { from: location }
        });
    }

    return (
        <div className="text-center">
            Welcome!
            <RecipeAutoCompleteTextbox value={searchText} onTextChanged={setSearchText} onSelectItem={onSelectItem} />
            {/* <ReducerTest /> */}
            {/* <LoadIndicatorTest /> */}
            {/* <LIpsum /> */}
        </div>
    );
}

interface IState {
    recipe: RecipeType
}

type IReducerAction = 
    | { type: REDUCER_ACTION_TYPE.Action1 }
    | { type: REDUCER_ACTION_TYPE.ADD_INGREDIENT }
    | { type: REDUCER_ACTION_TYPE.REMOVE_INGREDIENT, payload: { id: string } }

const enum REDUCER_ACTION_TYPE {
    Action1,
    ADD_INGREDIENT,
    REMOVE_INGREDIENT
}

function addIngredient(idx: number, id: number): RecipeIngredientType {
    let ri = RecipeHelper.initRecipeIngredient(idx);
    ri.id = String(id);
    return ri;
}

function reducer(state: IState, action: IReducerAction): IState {
    switch(action.type)
    {
        case REDUCER_ACTION_TYPE.Action1:
            console.log("action 1: state: " + state.recipe.totalTime);
            var newState = {...state };
            newState.recipe = {...newState.recipe};
            newState.recipe.totalTime = newState.recipe.totalTime + 1;
            return newState;
            // return { ...state, recipe: {...state.recipe, totalTime: state.recipe.totalTime + 1}};
        case REDUCER_ACTION_TYPE.ADD_INGREDIENT:
            let ids: number[] = state.recipe.ingredients.map(x => Number(x.id) || 0);
            console.log(ids);
            let nextId: number = (ids.length > 0 ? Math.max(...ids) : 0) + 1;
            return { ...state, recipe: {
                    ...state.recipe, ingredients: [
                        ...state.recipe.ingredients, addIngredient(state.recipe.ingredients.length, nextId)
                    ]
                }
            };
        case REDUCER_ACTION_TYPE.REMOVE_INGREDIENT:
            let newIngredients = state.recipe.ingredients.filter(x => x.id !== action.payload.id);
            let idx = 0;
            newIngredients.forEach(x => {
                x.index = String(idx);
                idx++;
            });
            return { ...state, recipe: {
                    ...state.recipe, ingredients: newIngredients
                }
            };
        default:
            const unhandledActionType: never = action;
            throw new Error(`Unexpected action type used in reducer: ${unhandledActionType}`);
    }
}

const initState: IState = {
    recipe: RecipeHelper.initRecipe()
};

function ReducerTest() {
    let [state, dispatch] = useReducer(reducer, initState);

    return (
        <div className="">
            <span className="d-block">Total time: {state.recipe.totalTime}</span>
            <button className="btn btn-secondary col-sm-6" onClick={() => dispatch({ type: REDUCER_ACTION_TYPE.Action1 })}>Incr</button>
            <span className="d-block">Ingredients: {state.recipe.ingredients.length}</span>
            <button className="btn btn-secondary col-sm-6" onClick={() => dispatch({ type: REDUCER_ACTION_TYPE.ADD_INGREDIENT })}>Incr</button>
            {state.recipe.ingredients.map(x => {
                return (
                    <div>
                        <span>Recipe ingredient id: {x.id}</span>
                        <span>Recipe ingredient index: {x.index}</span>
                        <button className="btn btn-secondary col-sm-6" onClick={() => dispatch({ type: REDUCER_ACTION_TYPE.REMOVE_INGREDIENT, payload: { id: x.id }})}>DEL</button>
                    </div>
                );
            })}
        </div>
    )
}


function LoadIndicatorTest() {
    let [text, setText] = useState<string>("");
    let [showLoadIndicator, setShowLoadIndicator] = useState<boolean>(false);

    const onDoSomething = useCallback(() => {
        setShowLoadIndicator(true);
        setTimeout(() => {
            setText("");
            setShowLoadIndicator(false);
        }, 5000);
    }, []);

    return (
        <BusyIndicator showIndicator={showLoadIndicator}>
            <div>
                <div className="form-group mb-2">
                    <InputControl type="text" label="Recipe name" name="name" value={text} onChange={(e) => setText(e.target.value)} />
                </div>
                <div className="form-group mb-2">
                    <InputControl type="text" label="Recipe name" name="name" value={text} onChange={(e) => setText(e.target.value)} />
                </div>
                <div className="d-flex flex-row-reverse mb-2">
                    <button className="btn btn-secondary col-sm-6" disabled={!text} onClick={onDoSomething}>Do something</button>
                </div>
            </div>
        </BusyIndicator>
    );

    // return (
    //     <div className="position-relative">
    //         <div>
    //             <div className="form-group mb-2">
    //                 <InputControl type="text" label="Recipe name" name="name" value={text} onChange={(e) => setText(e.target.value)} />
    //             </div>
    //             <div className="form-group mb-2">
    //                 <InputControl type="text" label="Recipe name" name="name" value={text} onChange={(e) => setText(e.target.value)} />
    //             </div>
    //             <div className="d-flex flex-row-reverse mb-2">
    //                 <button className="btn btn-secondary col-sm-6" disabled={!text} onClick={onDoSomething}>Do something</button>
    //             </div>
    //         </div>
    //         {showLoadIndicator ? <div className={"position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center zindex-modal-backdrop overlay"}>
    //             <div className="spinner-border spinner-border-lg text-info" role="status">
    //                 <span className="visually-hidden">Loading...</span>
    //             </div>
    //         </div> : null }
    //     </div>
    // );
}

function LIpsum() {
    return (
        <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Fringilla ut morbi tincidunt augue interdum velit euismod. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Faucibus in ornare quam viverra orci sagittis eu volutpat odio. Tincidunt eget nullam non nisi est sit amet facilisis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Blandit aliquam etiam erat velit scelerisque in dictum non. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. In arcu cursus euismod quis viverra nibh cras pulvinar. Magna eget est lorem ipsum dolor sit amet consectetur. Ac turpis egestas sed tempus. Est lorem ipsum dolor sit. Eget aliquet nibh praesent tristique magna. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Massa tincidunt dui ut ornare lectus. Elit pellentesque habitant morbi tristique senectus. Diam in arcu cursus euismod quis viverra. Dignissim convallis aenean et tortor.

A lacus vestibulum sed arcu non odio euismod. Varius morbi enim nunc faucibus a. Leo in vitae turpis massa sed elementum tempus. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Leo a diam sollicitudin tempor id eu nisl. Justo donec enim diam vulputate. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Non tellus orci ac auctor augue mauris augue neque gravida. Mi quis hendrerit dolor magna eget est. Maecenas accumsan lacus vel facilisis volutpat est velit. A scelerisque purus semper eget.

Penatibus et magnis dis parturient montes. Dui id ornare arcu odio ut sem nulla. Nisi scelerisque eu ultrices vitae auctor eu. Risus pretium quam vulputate dignissim suspendisse. Duis at tellus at urna condimentum mattis. Lacus luctus accumsan tortor posuere ac ut consequat. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Egestas pretium aenean pharetra magna. Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Felis donec et odio pellentesque diam volutpat commodo sed. Turpis massa sed elementum tempus egestas sed sed risus pretium. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Quis auctor elit sed vulputate.

Viverra nam libero justo laoreet. Quis commodo odio aenean sed adipiscing diam. Volutpat ac tincidunt vitae semper quis lectus nulla at. Ornare massa eget egestas purus viverra accumsan in. Massa tincidunt nunc pulvinar sapien et. At in tellus integer feugiat scelerisque varius morbi enim. Urna nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Urna porttitor rhoncus dolor purus non enim praesent elementum. Gravida cum sociis natoque penatibus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Malesuada fames ac turpis egestas sed tempus urna et. Id interdum velit laoreet id donec ultrices.

Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Fermentum iaculis eu non diam phasellus vestibulum. Tellus integer feugiat scelerisque varius morbi enim. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus laoreet non curabitur gravida. Pellentesque sit amet porttitor eget dolor. Dui sapien eget mi proin sed libero enim. Tristique magna sit amet purus gravida quis blandit turpis. Netus et malesuada fames ac turpis egestas sed. Blandit libero volutpat sed cras ornare arcu dui vivamus. Massa sed elementum tempus egestas. Viverra aliquet eget sit amet tellus cras adipiscing. Suscipit tellus mauris a diam maecenas sed enim. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu sem integer vitae justo eget. Imperdiet sed euismod nisi porta lorem mollis aliquam. In aliquam sem fringilla ut morbi. Suscipit tellus mauris a diam maecenas sed enim ut. Pellentesque sit amet porttitor eget dolor morbi non.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Fringilla ut morbi tincidunt augue interdum velit euismod. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Faucibus in ornare quam viverra orci sagittis eu volutpat odio. Tincidunt eget nullam non nisi est sit amet facilisis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Blandit aliquam etiam erat velit scelerisque in dictum non. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. In arcu cursus euismod quis viverra nibh cras pulvinar. Magna eget est lorem ipsum dolor sit amet consectetur. Ac turpis egestas sed tempus. Est lorem ipsum dolor sit. Eget aliquet nibh praesent tristique magna. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Massa tincidunt dui ut ornare lectus. Elit pellentesque habitant morbi tristique senectus. Diam in arcu cursus euismod quis viverra. Dignissim convallis aenean et tortor.

A lacus vestibulum sed arcu non odio euismod. Varius morbi enim nunc faucibus a. Leo in vitae turpis massa sed elementum tempus. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Leo a diam sollicitudin tempor id eu nisl. Justo donec enim diam vulputate. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Non tellus orci ac auctor augue mauris augue neque gravida. Mi quis hendrerit dolor magna eget est. Maecenas accumsan lacus vel facilisis volutpat est velit. A scelerisque purus semper eget.

Penatibus et magnis dis parturient montes. Dui id ornare arcu odio ut sem nulla. Nisi scelerisque eu ultrices vitae auctor eu. Risus pretium quam vulputate dignissim suspendisse. Duis at tellus at urna condimentum mattis. Lacus luctus accumsan tortor posuere ac ut consequat. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Egestas pretium aenean pharetra magna. Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Felis donec et odio pellentesque diam volutpat commodo sed. Turpis massa sed elementum tempus egestas sed sed risus pretium. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Quis auctor elit sed vulputate.

Viverra nam libero justo laoreet. Quis commodo odio aenean sed adipiscing diam. Volutpat ac tincidunt vitae semper quis lectus nulla at. Ornare massa eget egestas purus viverra accumsan in. Massa tincidunt nunc pulvinar sapien et. At in tellus integer feugiat scelerisque varius morbi enim. Urna nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Urna porttitor rhoncus dolor purus non enim praesent elementum. Gravida cum sociis natoque penatibus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Malesuada fames ac turpis egestas sed tempus urna et. Id interdum velit laoreet id donec ultrices.

Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Fermentum iaculis eu non diam phasellus vestibulum. Tellus integer feugiat scelerisque varius morbi enim. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus laoreet non curabitur gravida. Pellentesque sit amet porttitor eget dolor. Dui sapien eget mi proin sed libero enim. Tristique magna sit amet purus gravida quis blandit turpis. Netus et malesuada fames ac turpis egestas sed. Blandit libero volutpat sed cras ornare arcu dui vivamus. Massa sed elementum tempus egestas. Viverra aliquet eget sit amet tellus cras adipiscing. Suscipit tellus mauris a diam maecenas sed enim. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu sem integer vitae justo eget. Imperdiet sed euismod nisi porta lorem mollis aliquam. In aliquam sem fringilla ut morbi. Suscipit tellus mauris a diam maecenas sed enim ut. Pellentesque sit amet porttitor eget dolor morbi non.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Fringilla ut morbi tincidunt augue interdum velit euismod. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Faucibus in ornare quam viverra orci sagittis eu volutpat odio. Tincidunt eget nullam non nisi est sit amet facilisis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Blandit aliquam etiam erat velit scelerisque in dictum non. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. In arcu cursus euismod quis viverra nibh cras pulvinar. Magna eget est lorem ipsum dolor sit amet consectetur. Ac turpis egestas sed tempus. Est lorem ipsum dolor sit. Eget aliquet nibh praesent tristique magna. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Massa tincidunt dui ut ornare lectus. Elit pellentesque habitant morbi tristique senectus. Diam in arcu cursus euismod quis viverra. Dignissim convallis aenean et tortor.

A lacus vestibulum sed arcu non odio euismod. Varius morbi enim nunc faucibus a. Leo in vitae turpis massa sed elementum tempus. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Leo a diam sollicitudin tempor id eu nisl. Justo donec enim diam vulputate. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Non tellus orci ac auctor augue mauris augue neque gravida. Mi quis hendrerit dolor magna eget est. Maecenas accumsan lacus vel facilisis volutpat est velit. A scelerisque purus semper eget.

Penatibus et magnis dis parturient montes. Dui id ornare arcu odio ut sem nulla. Nisi scelerisque eu ultrices vitae auctor eu. Risus pretium quam vulputate dignissim suspendisse. Duis at tellus at urna condimentum mattis. Lacus luctus accumsan tortor posuere ac ut consequat. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Egestas pretium aenean pharetra magna. Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Felis donec et odio pellentesque diam volutpat commodo sed. Turpis massa sed elementum tempus egestas sed sed risus pretium. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Quis auctor elit sed vulputate.

Viverra nam libero justo laoreet. Quis commodo odio aenean sed adipiscing diam. Volutpat ac tincidunt vitae semper quis lectus nulla at. Ornare massa eget egestas purus viverra accumsan in. Massa tincidunt nunc pulvinar sapien et. At in tellus integer feugiat scelerisque varius morbi enim. Urna nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Urna porttitor rhoncus dolor purus non enim praesent elementum. Gravida cum sociis natoque penatibus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Malesuada fames ac turpis egestas sed tempus urna et. Id interdum velit laoreet id donec ultrices.

Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Fermentum iaculis eu non diam phasellus vestibulum. Tellus integer feugiat scelerisque varius morbi enim. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus laoreet non curabitur gravida. Pellentesque sit amet porttitor eget dolor. Dui sapien eget mi proin sed libero enim. Tristique magna sit amet purus gravida quis blandit turpis. Netus et malesuada fames ac turpis egestas sed. Blandit libero volutpat sed cras ornare arcu dui vivamus. Massa sed elementum tempus egestas. Viverra aliquet eget sit amet tellus cras adipiscing. Suscipit tellus mauris a diam maecenas sed enim. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu sem integer vitae justo eget. Imperdiet sed euismod nisi porta lorem mollis aliquam. In aliquam sem fringilla ut morbi. Suscipit tellus mauris a diam maecenas sed enim ut. Pellentesque sit amet porttitor eget dolor morbi non.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Fringilla ut morbi tincidunt augue interdum velit euismod. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Faucibus in ornare quam viverra orci sagittis eu volutpat odio. Tincidunt eget nullam non nisi est sit amet facilisis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Blandit aliquam etiam erat velit scelerisque in dictum non. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. In arcu cursus euismod quis viverra nibh cras pulvinar. Magna eget est lorem ipsum dolor sit amet consectetur. Ac turpis egestas sed tempus. Est lorem ipsum dolor sit. Eget aliquet nibh praesent tristique magna. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Massa tincidunt dui ut ornare lectus. Elit pellentesque habitant morbi tristique senectus. Diam in arcu cursus euismod quis viverra. Dignissim convallis aenean et tortor.

A lacus vestibulum sed arcu non odio euismod. Varius morbi enim nunc faucibus a. Leo in vitae turpis massa sed elementum tempus. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Leo a diam sollicitudin tempor id eu nisl. Justo donec enim diam vulputate. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Non tellus orci ac auctor augue mauris augue neque gravida. Mi quis hendrerit dolor magna eget est. Maecenas accumsan lacus vel facilisis volutpat est velit. A scelerisque purus semper eget.

Penatibus et magnis dis parturient montes. Dui id ornare arcu odio ut sem nulla. Nisi scelerisque eu ultrices vitae auctor eu. Risus pretium quam vulputate dignissim suspendisse. Duis at tellus at urna condimentum mattis. Lacus luctus accumsan tortor posuere ac ut consequat. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Egestas pretium aenean pharetra magna. Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Felis donec et odio pellentesque diam volutpat commodo sed. Turpis massa sed elementum tempus egestas sed sed risus pretium. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Quis auctor elit sed vulputate.

Viverra nam libero justo laoreet. Quis commodo odio aenean sed adipiscing diam. Volutpat ac tincidunt vitae semper quis lectus nulla at. Ornare massa eget egestas purus viverra accumsan in. Massa tincidunt nunc pulvinar sapien et. At in tellus integer feugiat scelerisque varius morbi enim. Urna nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Urna porttitor rhoncus dolor purus non enim praesent elementum. Gravida cum sociis natoque penatibus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Malesuada fames ac turpis egestas sed tempus urna et. Id interdum velit laoreet id donec ultrices.

Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Fermentum iaculis eu non diam phasellus vestibulum. Tellus integer feugiat scelerisque varius morbi enim. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus laoreet non curabitur gravida. Pellentesque sit amet porttitor eget dolor. Dui sapien eget mi proin sed libero enim. Tristique magna sit amet purus gravida quis blandit turpis. Netus et malesuada fames ac turpis egestas sed. Blandit libero volutpat sed cras ornare arcu dui vivamus. Massa sed elementum tempus egestas. Viverra aliquet eget sit amet tellus cras adipiscing. Suscipit tellus mauris a diam maecenas sed enim. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu sem integer vitae justo eget. Imperdiet sed euismod nisi porta lorem mollis aliquam. In aliquam sem fringilla ut morbi. Suscipit tellus mauris a diam maecenas sed enim ut. Pellentesque sit amet porttitor eget dolor morbi non.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec pretium vulputate sapien nec sagittis aliquam malesuada. Fringilla ut morbi tincidunt augue interdum velit euismod. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Faucibus in ornare quam viverra orci sagittis eu volutpat odio. Tincidunt eget nullam non nisi est sit amet facilisis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus. Blandit aliquam etiam erat velit scelerisque in dictum non. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. In arcu cursus euismod quis viverra nibh cras pulvinar. Magna eget est lorem ipsum dolor sit amet consectetur. Ac turpis egestas sed tempus. Est lorem ipsum dolor sit. Eget aliquet nibh praesent tristique magna. Sagittis id consectetur purus ut faucibus pulvinar elementum integer. Massa tincidunt dui ut ornare lectus. Elit pellentesque habitant morbi tristique senectus. Diam in arcu cursus euismod quis viverra. Dignissim convallis aenean et tortor.

A lacus vestibulum sed arcu non odio euismod. Varius morbi enim nunc faucibus a. Leo in vitae turpis massa sed elementum tempus. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Leo a diam sollicitudin tempor id eu nisl. Justo donec enim diam vulputate. Nunc scelerisque viverra mauris in aliquam sem fringilla ut. Non tellus orci ac auctor augue mauris augue neque gravida. Mi quis hendrerit dolor magna eget est. Maecenas accumsan lacus vel facilisis volutpat est velit. A scelerisque purus semper eget.

Penatibus et magnis dis parturient montes. Dui id ornare arcu odio ut sem nulla. Nisi scelerisque eu ultrices vitae auctor eu. Risus pretium quam vulputate dignissim suspendisse. Duis at tellus at urna condimentum mattis. Lacus luctus accumsan tortor posuere ac ut consequat. Turpis egestas maecenas pharetra convallis posuere morbi leo urna. Egestas pretium aenean pharetra magna. Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Felis donec et odio pellentesque diam volutpat commodo sed. Turpis massa sed elementum tempus egestas sed sed risus pretium. Sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum. Quis auctor elit sed vulputate.

Viverra nam libero justo laoreet. Quis commodo odio aenean sed adipiscing diam. Volutpat ac tincidunt vitae semper quis lectus nulla at. Ornare massa eget egestas purus viverra accumsan in. Massa tincidunt nunc pulvinar sapien et. At in tellus integer feugiat scelerisque varius morbi enim. Urna nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Urna porttitor rhoncus dolor purus non enim praesent elementum. Gravida cum sociis natoque penatibus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus. Malesuada fames ac turpis egestas sed tempus urna et. Id interdum velit laoreet id donec ultrices.

Volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Fermentum iaculis eu non diam phasellus vestibulum. Tellus integer feugiat scelerisque varius morbi enim. Diam sollicitudin tempor id eu nisl nunc mi ipsum. Lacus laoreet non curabitur gravida. Pellentesque sit amet porttitor eget dolor. Dui sapien eget mi proin sed libero enim. Tristique magna sit amet purus gravida quis blandit turpis. Netus et malesuada fames ac turpis egestas sed. Blandit libero volutpat sed cras ornare arcu dui vivamus. Massa sed elementum tempus egestas. Viverra aliquet eget sit amet tellus cras adipiscing. Suscipit tellus mauris a diam maecenas sed enim. Amet risus nullam eget felis eget nunc lobortis mattis aliquam. Eu sem integer vitae justo eget. Imperdiet sed euismod nisi porta lorem mollis aliquam. In aliquam sem fringilla ut morbi. Suscipit tellus mauris a diam maecenas sed enim ut. Pellentesque sit amet porttitor eget dolor morbi non.
        </div>
    )
}