
import styles from "../controls/inputControl.module.scss";

type InputControlPropType = {
    name: string,
    label?: string,
    type: "text" | "password" | "email" | "search" | "textarea",
    autoFocus?: boolean,
    required?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    placeholder?: string,
    className?: string,
    additionalInputClass?: string,
    maxLength?: number,
    autocomplete?: "on" | "off",
    value: string,
    onChange?(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void,
    onKeyDown?(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void
} & React.PropsWithChildren

export default function InputControl(props: InputControlPropType) {

    let isDisabled = props.disabled !== undefined && props.disabled;

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    let autocomplete = props.autocomplete || "on";

    let inputClass = "form-control";
    if(props.label !== undefined) {
        inputClass += " mt-3";
    }
    if(props.additionalInputClass) {
        inputClass += " " + props.additionalInputClass;
    }

    return (
        // Outer div required so labels align correctly
        <div className="w-100">
            <div className={styles.inputControl + (props.className ? " " + props.className : "")}>
                {props.label ? <label htmlFor={props.name}>{props.label}</label> : null}
                {props.type === "textarea" ? 
                    <textarea autoFocus={props.autoFocus}
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                        onKeyDown={props.onKeyDown}
                        className={inputClass}
                        placeholder={props.placeholder}
                        required={props.required}
                        readOnly={props.readOnly}
                        disabled={isDisabled}
                        autoComplete={autocomplete}/>
                        :
                    <input autoFocus={props.autoFocus}
                        type={props.type}
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                        onKeyDown={props.onKeyDown}
                        className={inputClass}
                        placeholder={props.placeholder}
                        required={props.required}
                        readOnly={props.readOnly}
                        disabled={isDisabled}
                        autoComplete={autocomplete}/>}
                {props.maxLength && !props.readOnly && !isDisabled ? <span className={"fs-smaller fw-lighter fst-italic" + (props.value.length > props.maxLength ? " text-danger" : "")}>{props.value.length}/{props.maxLength}</span> : null}
            </div>
        </div>
    )
}