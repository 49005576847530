import { ApiOwner, Owner } from "./ownerHelper"
import RecipeHelper, { ApiRecipeType, RecipeType } from "./recipeHelper"
import Util from "./util"

export type ApiMealPlanRecipe = {
    id: string,
    recipe: ApiRecipeType
}

export type ApiMealPlan = {
    id: string,
    owner: ApiOwner,
    isCurrent: boolean,
    recipes: ApiMealPlanRecipe[]
}

export type MealPlanRecipe = {
    id: string,
    recipe: RecipeType
}

export type MealPlan = {
    id: string,
    owner: Owner,
    isCurrent: boolean,
    recipes: MealPlanRecipe[]
}

export default class MealPlanHelper {
    static initMealPlan(isCurrent: boolean): MealPlan {
        return {
            id: "",
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            isCurrent: isCurrent,
            recipes: []
        };
    }

    static mealPlanFromApi(dataMealPlan: ApiMealPlan) {
        let mealPlan: MealPlan = {
            id: String(dataMealPlan.id),
            owner: {
                alias: Util.parseStr(dataMealPlan.owner.alias),
                ownerId: Util.parseStr(dataMealPlan.owner.ownerId),
                ownerId2: Util.parseStr(dataMealPlan.owner.ownerId2)
            },
            isCurrent: dataMealPlan.isCurrent,
            recipes: []
        };

        dataMealPlan.recipes.forEach((mpRecipe: ApiMealPlanRecipe) => {
            mealPlan.recipes.push({
                id: mpRecipe.id,
                recipe: RecipeHelper.recipeFromApi(mpRecipe.recipe)
            })
        });

        return mealPlan;
    }

    static convertMealPlanToApiJson(mealPlan: MealPlan): ApiMealPlan {
        let apiMP: ApiMealPlan = {
            id: String(mealPlan.id),
            //TODO2: Make not required
            owner: {
                alias: "",
                ownerId: "",
                ownerId2: ""
            },
            isCurrent: mealPlan.isCurrent,
            recipes: []
        };

        apiMP.recipes = MealPlanHelper.convertMealPlanRecipesToApiJson(mealPlan.recipes);

        return apiMP;
    }

    static convertMealPlanRecipeToApiJson(mpRecipe: MealPlanRecipe): ApiMealPlanRecipe {
        return {
            id: String(mpRecipe.id),
            recipe: RecipeHelper.convertRecipeToApiJson(mpRecipe.recipe)
        };
    }

    static convertMealPlanRecipesToApiJson(mpRecipes: MealPlanRecipe[]): ApiMealPlanRecipe[] {
        return mpRecipes.map(mpRecipe => MealPlanHelper.convertMealPlanRecipeToApiJson(mpRecipe));
    }

    // static validate(recipe: Recipe): RecipeValidationResult {

    //     let result: RecipeValidationResult = {
    //         fieldErrors: [],
    //         ingredientErrors: [],
    //         instructionErrors: []
    //     };

    //     result.ingredientErrors = this.validateIngredients(recipe.ingredients);
    //     result.instructionErrors = this.validateInstructions(recipe.instructions);
    //     return result;
    // }
}