
import React, { useState } from 'react';
import AutoCompleteTextbox from "./autoCompleteTextbox";
import useFetch from "../../hooks/useFetch";
import RecipeHelper, { RecipeType } from "../../helpers/recipeHelper";


interface IRecipeAutoCompleteTextboxProps {
    isReadOnly?: boolean,
    value: string,
    onTextChanged(value: string): void,
    onSelectItem(selectedValue: RecipeType): void
}

// Table tut: https://www.youtube.com/watch?v=dYjdzpZv5yc
// Fragments (multiple child elements): https://reactjs.org/docs/fragments.html

// React tut: https://www.youtube.com/watch?v=Ke90Tje7VS0

// Drag/drop: https://www.w3schools.com/jsref/event_ondragend.asp

// When your own table gets too complicated: https://react-table.tanstack.com/docs/overview
export function RecipeAutoCompleteTextbox(props: IRecipeAutoCompleteTextboxProps) {

    const [searchResults, setSearchResults] = useState<RecipeType[]>([]);
    const { getJson } = useFetch();

    function onTextChanged(value: string)
    {
        if(value.length > 2) {
            let data = {
                name: value,
                owner: "",
                ownerId: "",
                tags: []
            };
            
            //TODO2: Pagination
            getJson("recipe/search", data).then((result: any) => {
                setSearchResults(RecipeHelper.recipesFromApi(result.recipes));
            });
        }
        else {
            setSearchResults([]);
        }

        props.onTextChanged(value);
    }

    function onSearchResultSelected(selectedItem: RecipeType)
    {
        props.onSelectItem(selectedItem);
    }
    
    return (
        <AutoCompleteTextbox<RecipeType> isReadOnly={false} placeholder="Search for a recipe..."
                                value={props.value}
                                results={searchResults}
                                clearResults={() => setSearchResults([])}
                                onTextChanged={onTextChanged}
                                onSelectItem={onSearchResultSelected}
                                getResultKey={x => x.id}
                                getResultDisplay={x => x.name}
                                isTextResultMatch={() => false}/>
    );
}