import React from 'react';
import ModalDialog from './utilities/modalDialog';

type SuccessIndicatorPropsType = {
	onOk(): void,
	message?: string
} & React.PropsWithChildren

//TODO2: Success icon like one of these:
// - https://codepen.io/istiaktridip/pen/BZqaOd
// - https://codepen.io/scottloway/pen/zqoLyQ
export default function SuccessIndicator(props: SuccessIndicatorPropsType) {
	return (
		<ModalDialog isSuccessLayout={true} titleContent="Success" onClose={() => props.onOk()} onOk={() => props.onOk()}>
			{props.message || "Saved successfully!"}
		</ModalDialog>
	);
}