

import React from 'react';
import { MealPlanRecipe } from '../helpers/mealPlanHelper';
import { Link } from "react-router-dom";
import AsyncStatusButton, { AsyncStatusButtonType } from "./controls/asyncStatusButton";

type MealPlanRecipeListProps = {
	title?: string,
    recipes: MealPlanRecipe[],
	markAsConsumed?(recipes: MealPlanRecipe[]): Promise<boolean>,
	removeMealPlanRecipes(recipes: MealPlanRecipe[]): Promise<boolean>,
	onPromoteRecipe?(recipe: MealPlanRecipe): Promise<boolean>,
	onDemoteRecipe?(recipe: MealPlanRecipe): Promise<boolean>,
	onAddToGroceryList(recipe: MealPlanRecipe): Promise<boolean>
	onAddAllToGroceryList(recipes: MealPlanRecipe[]): Promise<boolean>
} & React.PropsWithChildren


function MealPlanRecipeList(props: MealPlanRecipeListProps) {

	function createListItem(mpRecipe: MealPlanRecipe) {
		return (
			<div key={`${mpRecipe.recipe.id}`} className="row">
				<div className="d-flex">
					
					{ props.markAsConsumed ?
						<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={() => props.markAsConsumed!([mpRecipe])}><i className="bi-robot"></i></AsyncStatusButton> : null}
					
					<AsyncStatusButton buttonType={AsyncStatusButtonType.DELETE} onClick={() => props.removeMealPlanRecipes([mpRecipe])}></AsyncStatusButton>

					{ props.onPromoteRecipe ?
						<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={() => props.onPromoteRecipe!(mpRecipe)}><i className="bi-arrow-up"></i></AsyncStatusButton> : null}

					{ props.onDemoteRecipe ?
						<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={() => props.onDemoteRecipe!(mpRecipe)}><i className="bi-arrow-down"></i></AsyncStatusButton> : null}

					<div className="flex-fill text-break">
						<Link to={"/recipe/" + mpRecipe.recipe.id} className="align-top">{mpRecipe.recipe.name}</Link>
					</div>
					{ props.onAddToGroceryList ?
						<div className="float-end">
							<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={() => props.onAddToGroceryList!(mpRecipe)}><i className="bi-card-checklist"></i></AsyncStatusButton>
						</div> : null}
				</div>
			</div>
		)
	}

	function createGroupItem(mpRecipes: MealPlanRecipe[], heading: string) {
		if(mpRecipes.length === 0) {
			return null;
		}

		let headingContent = null;
		if(heading) {
			headingContent = (
				<label>{heading}</label>
			);
		}

		return (
			<div>
				{headingContent}
				{mpRecipes.map((mpRecipe: MealPlanRecipe) => {
					return createListItem(mpRecipe)
				})}
			</div>
		)
	}

    return (
        <div className="">
			<div className="card-header d-flex">
				<div className="d-flex flex-nowrap">
					{ props.markAsConsumed ?
						<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} disabled={props.recipes.length < 1} displayClassName="d-inline" margin="me-2" onClick={() => props.markAsConsumed!(props.recipes)}><i className="bi-robot"></i></AsyncStatusButton>
					: null }
					<AsyncStatusButton buttonType={AsyncStatusButtonType.DELETE} disabled={props.recipes.length < 1} displayClassName="d-inline" onClick={() => props.removeMealPlanRecipes(props.recipes)}></AsyncStatusButton>
				</div>

				<h6 className="text-center flex-fill">{props.title}</h6>

				{ props.onAddAllToGroceryList ?
					<div className="">
						<AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} disabled={props.recipes.length < 1} onClick={() => props.onAddAllToGroceryList!(props.recipes)}><i className="bi-card-checklist"></i></AsyncStatusButton>
					</div> : null }
			</div>
			{createGroupItem(props.recipes, "")}
		</div>
    );
}

export default MealPlanRecipeList;
