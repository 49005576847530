
import React from 'react';
import GroceryListHelper, { GroceryListItemType, GroceryListType } from "../../helpers/groceryListHelper";
import { GroceryListItem } from "./groceryListItem";


type GroceryListingPropType = {
    showOnlyUnselectedItems: boolean,
    isShoppingView: boolean,
    groceryList: GroceryListType,
    onGroceryListItemChanged(oldItem: GroceryListItemType, newItem: GroceryListItemType): void,
    onGroceryListItemsChanged(groceryListItems: GroceryListItemType[]): void
} & React.PropsWithChildren

export function GroceryListing(props: GroceryListingPropType) {
    
    function removeItem(item: GroceryListItemType) {
        let listItems = [...props.groceryList.items];
        let removedItemIdx = listItems.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, item));
        if(removedItemIdx >= 0) {
            listItems.splice(removedItemIdx, 1);
            props.onGroceryListItemsChanged(listItems);
        }
    }

    function moveItemUp(item: GroceryListItemType) {
        let listItems = [...props.groceryList.items];
        let movedItemIdx = listItems.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, item));
        
        let idxToMove = movedItemIdx - 1;
        // Showing only unselected items. The item needs to move above the next unselected item.
        if(props.showOnlyUnselectedItems) {
            let canMove = false;
            while(!canMove && idxToMove >= 0) {
                if(!listItems[idxToMove].selected) {
                    canMove = true;
                }
                else {
                    idxToMove--;
                }
            }
        }

        // Checking also that we can move the item to the new location
        if(idxToMove >= 0) {
            let movedItem = listItems.splice(movedItemIdx, 1)[0];
            listItems.splice(idxToMove, 0, movedItem);
            props.onGroceryListItemsChanged(listItems);
        }
    }

    function moveItemDown(item: GroceryListItemType) {
        let listItems = [...props.groceryList.items];
        let movedItemIdx = listItems.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, item));
        // let movedItem = listItems.splice(movedItemIdx, 1)[0];

        let idxToMove = movedItemIdx + 1;
        // Showing only unselected items. The item needs to move below the next unselected item.
        if(props.showOnlyUnselectedItems) {
            let canMove = false;
            while(!canMove && idxToMove < listItems.length) {
                if(!listItems[idxToMove].selected) {
                    canMove = true;
                }
                else {
                    idxToMove++;
                }
            }
        }

        // Checking also that we can move the item to the new location
        if(idxToMove < listItems.length) {
            let movedItem = listItems.splice(movedItemIdx, 1)[0];
            listItems.splice(idxToMove, 0, movedItem);
            props.onGroceryListItemsChanged(listItems);
        }
    }

    function createGroceryListItem(showUpArrow: boolean, showDownArrow: boolean, item: GroceryListItemType) {
        return <GroceryListItem key={item.index} isShoppingView={props.isShoppingView} showUpArrow={showUpArrow} showDownArrow={showDownArrow} groceryListItem={item} onItemChanged={props.onGroceryListItemChanged} removeItem={removeItem} moveItemUp={moveItemUp} moveItemDown={moveItemDown}/>
    }

    let itemsThrough = 0;
    let items = props.showOnlyUnselectedItems ? props.groceryList.items.filter(x => !x.selected) : props.groceryList.items;

    if(props.isShoppingView) {
        if(GroceryListHelper.isGroupedByDepartment(items)) {
            let groceryListGroups: {
                groupName: string,
                items: GroceryListItemType[]
            }[] = [];
    
            items.forEach(item => {
                let idx = groceryListGroups.findIndex(x => x.groupName === item.department);
                if(idx < 0) {
                    idx = groceryListGroups.push({
                        groupName: item.department,
                        items: []
                    }) - 1;
                }
                groceryListGroups[idx].items.push(item);
            });

            return (
                <div>
                    {groceryListGroups.map(group => {
                        return (
                            <div key={group.groupName}>
                                <span className="fw-bold">{group.groupName || ""}</span>
                                <div className="ps-2">
                                    {group.items.map(item => createGroceryListItem(false, false, item))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
        else {

            // Display for shopping view not grouped by department
            return (
                <div>
                    {items.map((item: GroceryListItemType) => {
                        return createGroceryListItem(false, false, item);
                    })}
                </div>
            );
        }
    }

    let groceryListItems = items.map((item: GroceryListItemType) => {
        let isFirstItem = itemsThrough === 0;
        let isLastItem = itemsThrough + 1 === items.length;
        itemsThrough++;
        // Show down arrow for all rows not last row or if it is the last row and it has a value - because then another empty line will be added below.
        return createGroceryListItem(!isFirstItem, !isLastItem, item)
    });

    return (
        // Responsive on mobile
        // - https://elvery.net/demo/responsive-tables/
        // - https://css-tricks.com/responsive-data-tables/
        <table>
            <colgroup>
                {/* Index */}
                <col span={1} style={{width: "0%"}} />
                {/* Actions */}
                <col span={1} style={{width: "10%"}} />
                {/* Item text */}
                <col span={1} style={{width: "35%"}} />
                {/* Recipe name */}
                <col span={1} style={{width: "15%"}} />
                {/* Qty */}
                <col span={1} style={{width: "18%"}} />
                {/* UoM */}
                <col span={1} style={{width: "10%"}} />
                {/* Department */}
                <col span={1} style={{width: "0%"}} />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col" className="visually-hidden">Index</th>
                    <th scope="col">Actions</th>
                    <th scope="col">Item</th>
                    <th scope="col">Recipe</th>
                    <th scope="col">Qty</th>
                    <th scope="col">UoM</th>
                    <th scope="col">Department</th>
                </tr>
            </thead>
            <tbody>
                {groceryListItems}
            </tbody>
        </table>
    );
}